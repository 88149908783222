import router from '@/router'
import Swal from 'sweetalert2'
// 代入傳回的使用者資料
export const login = (state, data) => {
  state.jwt = data.jwt
  state.showBackstage = data.showBackstage
  state.email_verified = data.email_verified
}
// export const login2 = (state, data) => {
//   state.jwt = data.jwt
// }
// 清空資料
export const logout = (state) => {
  state.isPersonUser = true
  state.business = {}
  state.jwt = ''
  state.page = {
    member: false,
    post: false,
    tour: false,
    hotel: false,
    spot: false,
    other: false,
    access: false,
    page: false,
    links: false,
    review: false,
    tag: false
  }
  state.premium_at = ''
  state.referrer = ''
  state.referrer_id = '0'
  state.remember_referrer_id = ''
  state.remember_referrer_name = ''
  state.email_verified = false
  state.profile_photo_path = ''
  state.showBackstage = false
  state.id = ''
  state.username = ''
  state.role = 0
  state.email = ''
  state.birthday = ''
  state.lineId = ''
  state.gender = ''
  state.referrer_data = []
  state.group_leader = []
  state.group_member = []
  state.guideGroupId = ''
  state.guideGroup = ''
  state.guideData = {
    id_number: '',
    phone: '',
    introducer: '',
    insurance: '',
    laborInsurance: '',
    employer: '',
    roles: {
      role1: '',
      role2: '',
      role3: '',
      role4: ''
    },
    langSelected: {
      l_cmn: '',
      l_eng: '',
      l_jpn: '',
      l_fra: '',
      l_deu: '',
      l_spa: '',
      l_kor: '',
      l_tha: '',
      l_ara: '',
      l_rus: '',
      l_ita: '',
      l_vie: '',
      l_ind: '',
      l_may: '',
      l_tur: ''
    }
  }
  // router.push('/')
  Swal.fire({
    icon: 'success',
    text: '登出成功',
    showConfirmButton: false,
    timer: 1000
  })
  console.log('登出成功')
  if (router.history.current.path !== '/') {
    router.push('/')
  }
  // router.go(0)
}

export const checkJWT = (state, data) => {
  state.id = data.message.id
  state.page = { ...data.page }
}

// 取得使用者資料
export const getInfo = (state, data) => {
  // 一般會員資料
  state.page = { ...data.page }
  // console.log(state.page)
  state.birthday = data.data.birthday
  state.birthD = data.data.birthD
  state.birthM = data.data.birthM
  state.birthY = data.data.birthY
  state.lineId = data.data.lineId
  state.email = data.data.email
  state.gender = data.data.gender
  state.name = data.data.name
  state.username = data.data.username
  state.profile_photo_path = data.data.profile_photo_path
  state.referrer = data.data.referrer
  state.referrer_id = data.data.referrer_id
  state.premium_at = data.data.premium_at
  state.referrer_data = data.data.referrer_data
  state.group_leader = data.data.group_leader
  state.group_member = data.data.group_member
  // 導領資料
  if (data.guide === null) {
    state.guideData = null
  } else state.guideData = { ...data.guide }
}
export const getInfo2 = (state, data) => {
  // 企業資料
  state.business = { ...data.data[0] }
  // console.log(state.business)
}
export const isPersonUser = (state) => {
  state.isPersonUser = false
}

export const referrer = (state, data) => {
  // console.log(data)
  state.remember_referrer_id = data.id
  state.remember_referrer_name = data.username
}
export const joinGuideGroup = (state, { routerLink, data }) => {
  state.guideGroupId = routerLink
  state.remember_group_name = data.group.name
  // console.log(state)
  // state.guideGroup = data.username
}
export const clearGuideGroup = (state) => {
  state.guideGroupId = ''
  state.guideGroup = ''
  state.remember_group_name = ''
}

export const clearReferrer = (state) => {
  state.remember_referrer_id = ''
  state.remember_referrer_name = ''
}
export const checkEmail = (state, data) => {
  state.check_email = data
}
