import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
const host = window.location.host
const reg = /^localhost+/
let url = ''
// import router from '../router'
// import store from '../store'

// 判斷網址不是localhost時，baseURL = VUE_APP_API192，讓Network(192.168...)也能連正確api
if (reg.test(host)) {
  url = process.env.VUE_APP_API
} else url = process.env.VUE_APP_API192

export const api = axios.create({
  baseURL: url
})

// export const api = axios.create({
//   baseURL: process.env.VUE_APP_API
// })
export const tstarapi = axios.create({
  baseURL: process.env.VUE_APP_API_TEST2
})
export const geawapi = axios.create({
  baseURL: process.env.VUE_APP_API_GEAW
})
// api.interceptors.response.use(res => {
//   return res
// }, error => {
//   // 如果 axios 有收到回傳的東西
//   if (error.response) {
//     // 如果錯誤是 401，JWT 可能過期了
//     if (error.response.status === 401) {
//       // 登出
//       store.commit('user/logout')
//       router.push('/login')
//     }
//   }
//   return Promise.reject(error)
// })
Vue.use(VueAxios, { axios, api, geawapi, tstarapi })
