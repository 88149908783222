import { api } from '@/plugins/axios.js'
import router from '@/router'
import store from '@/store'
import Swal from 'sweetalert2'
export const login = async ({ commit, dispatch }, loginForm) => {
  console.log(loginForm)
  try {
    const { data } = await api.post('php_modules/auth-file/login.php', loginForm)
    console.log(data)
    if (data.success === true) {
      commit('clearReferrer') // 清除記在localstorage的推薦人資料
      await commit('login', data)
      await dispatch('checkJWT', 'getInfo')
      await Swal.fire({
        icon: 'success',
        text: '登入成功',
        showConfirmButton: false,
        timer: 1000
      })
    } else if (data.message === '帳號或密碼錯誤') {
      alert('您輸入的電子信箱或密碼錯誤，請再次確認')
      return
    } else alert(data.message)

    // 會員登入後直接到企管後台或會員中心
    // if (store.state.user.isPersonUser) {
    //   router.push('/member')
    // } else {
    //   router.push('business-member')
    // }

    router.go(0)
  } catch (error) {
    alert(error)
  }
}

// 檢查JWT是否過期
export const checkJWT = async ({ commit, state, dispatch }, x) => {
  if (state.jwt.length === 0) return
  try {
    const { data } = await api.get('php_modules/auth-file/read-user.php', {
      headers: {
        authorization: state.jwt
      }
    })
    // console.log(data)
    if (data.success === true) {
      await commit('checkJWT', data)
      if (x === 'getInfo') {
        if (data.message.isPersonUser) {
          await dispatch('getInfo')
        } else {
          commit('isPersonUser')
          await dispatch('getInfo2')
        }
      }
    } else if (data.message === 'Expired token') {
      await Swal.fire({
        icon: 'error',
        text: '請重新登入',
        showConfirmButton: false,
        timer: 1500
      })
      store.commit('user/logout')
    } else {
      alert(data.message)
      store.commit('user/logout')
    }
  } catch (error) {
    alert(error)
    store.commit('user/logout')
  }
}
export const getInfo = async ({ commit, state }) => {
  if (state.jwt.length === 0) return
  try {
    // const { data } = await api.post('php_modules/auth-file/user.php?type=r&jwt=' + state.jwt)
    const { data } = await api.post('php_modules/auth-file/user.php?type=r&id=' + state.id)
    // console.log(data)
    if (data.success === true) store.commit('user/getInfo', data)
  } catch (error) {
    console.log(error)
  }
}
export const getInfo2 = async ({ commit, state }) => {
  if (state.jwt.length === 0) return
  try {
    // const { data } = await api.post('php_modules/auth-file/user.php?type=r&jwt=' + state.jwt)
    const { data } = await api.post('php_modules/auth-file/company.php?type=r&id=' + state.id)
    // console.log(data)
    if (data.success === true) store.commit('user/getInfo2', data)
  } catch (error) {
    console.log(error)
  }
}
// export const checkPSWtoken = async (routerLink) => {
//   console.log(routerLink)
//   try {
//     const { data } = await api.get('php_modules/auth-file/read-user.php?type=r', routerLink)
//     // console.log(state.getPSWtoken)
//     // console.log(data)
//     if (data.success !== true) {
//       alert(data.message)
//       router.go(-1)
//     }
//   } catch (error) {
//     console.log(error)
//   }
// }

// export const logout = async ({ commit, state }) => {
//   try {
//     await api.delete({}, {
//       headers: {
//         authorization: state.jwt
//       }
//     })
//     commit('logout')
//     router.push('/')
//     alert('登出成功')
//   } catch (error) {
//     alert('失敗')
//     console.log(error)
//   }
// }
