<template>
  <footer id="footer">
    <b-container>
      <div class="py-4">
        <b-nav class="mb-2 dashlink" align="center">
          <b-nav-item to='/about'>關於我們</b-nav-item>
          <b-nav-item to='/board' class="">理監事會</b-nav-item>
          <b-nav-item to='/links' class="">友善連結</b-nav-item>
        </b-nav>
        <strong>台灣ESG旅遊</strong>
        <div class="subtxt">聯絡信箱：service@esg888.com.tw</div>
        <!-- <div class="d-flex">
          <div class="subtxt mt-2"><a href='/links'>友善連結</a></div>
        </div> -->
      </div>
    </b-container>
    <!-- 行動版會出現固定於置底 -->
    <section
      class="mobile_footer d-md-none p-2"
      v-if="this.$route.path === '/'"
      >
      <div v-if="!user.isLogin" class=" d-flex justify-content-around">
        <div v-b-modal.login_modal>登入</div>
        <div v-b-modal.email_verify>註冊</div>
      </div>
      <div v-if="user.isLogin" class=" d-flex justify-content-around">
        <router-link to='/member'><b-btn class="btn1">會員中心</b-btn></router-link>
      </div>
    </section>
    <!-- 填充用 -->
    <section
      class="d-md-none p-2"
      v-if="this.$route.path === '/'"
      >
      <div>台灣ESG旅遊</div>
    </section>
  </footer>
</template>

<script>
export default {
  data () {
    return {}
  },
  async create () {
    // 如果是以下頁面則跳過此處SEO設定，因為該頁本身就有SEO設定。(商品 旅遊行程 旅館...)

    // 查詢自訂SEO並設定
    try {
      const { data } = await this.api.post('php_modules2/site_pages.php?type=r&version=3')
      // console.log(data)
      // 檢查後台是否有設定目前網址的SEO、聯絡人資料，如果有設定，則代入資料
      const dataIsExits = data.data.find(item => item.url === window.location.href)
      if (dataIsExits) {
        this.contactItems = dataIsExits.contact
        this.metaTitle = dataIsExits.title
        this.metaData.push({
          vmid: 'keywords',
          name: 'keywords',
          content: dataIsExits.keyword
        },
        {
          vmid: 'description',
          name: 'description',
          content: dataIsExits.description
        })
      }
      // esim 單獨介紹頁keywords、description用後台設定的ESIM首頁
      if (this.$route.name === 'EsimDetailPage') {
        const seoData = data.data.find(item => item.url === 'https://tstar888.com/tours/esim')
        this.metaTitle = seoData.title
        this.metaData.push({
          vmid: 'keywords',
          name: 'keywords',
          content: seoData.keyword
        },
        {
          vmid: 'description',
          name: 'description',
          content: seoData.description
        })
      }
    } catch (error) {
      this.$swal({
        icon: 'error',
        title: '錯誤',
        text: error
      })
    }
  }
}
</script>

<style lang="scss">
#footer {
  color: #6b793d;
  background-color: #eee;
  .mobile_footer {
    z-index: 99;
    border-top: 1px solid #eee;
    background-color: rgb(255, 255, 255);
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
</style>
