import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/page-guide',
    name: 'HomeGuide',
    component: HomeView
  },

  // 會員中心
  {
    path: '/member',
    name: 'MemberPage',
    component: () => import(/* webpackChunkName: "MemberPage" */ '../views/MemberPage.vue'),
    meta: {
      login: true
    }
  },
  // 企業會員中心
  {
    path: '/business-member',
    name: 'BMemberPage',
    component: () => import(/* webpackChunkName: "BMemberPage" */ '../views/BMemberPage.vue'),
    meta: {
      login: true
    }
  },
  // 小組專區
  {
    path: '/guidegroup',
    name: 'GuideGroup',
    component: () => import(/* webpackChunkName: "GuideGroup" */ '../views/GuideGroup.vue'),
    meta: {
      login: true
    }
  },

  // 管理後台  // pageAccess對應到管理後台的頁面及權限
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminIndex.vue'),
    children: [
      { // 測試聊天室功能
        path: 'chatGPT',
        component: () => import(/* webpackChunkName: "admin" */ '../views/chatGPT.vue'),
        meta: {
          login: true,
          pageAccess: 'adminIndex'
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminWelcom.vue'),
        meta: {
          login: true,
          pageAccess: 'adminIndex'
        }
      },
      {
        path: 'access',
        name: 'AdminAccess',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminAccess.vue'),
        meta: {
          login: true,
          pageAccess: 'access'
        }
      },
      {
        path: 'page',
        name: 'AdminPage',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPage.vue'),
        meta: {
          login: true,
          pageAccess: 'page'
        }
      },
      {
        path: 'seo',
        name: 'AdminSeo',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSeo.vue'),
        meta: {
          login: true,
          pageAccess: 'seo'
        }
      },
      {
        path: 'seo3',
        name: 'AdminSeo3',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminSeo3.vue'),
        meta: {
          login: true,
          pageAccess: 'seo'
        }
      },
      {
        path: 'guidegroup',
        name: 'AdminGroup',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminGuideGroup.vue'),
        meta: {
          login: true,
          pageAccess: 'member'
        }
      },
      {
        path: 'member',
        name: 'AdminMember',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMember.vue'),
        meta: {
          login: true,
          pageAccess: 'member'
        }
      },
      {
        path: 'business_member',
        name: 'AdminBMember',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminBMember.vue'),
        meta: {
          login: true,
          pageAccess: 'member'
        }
      },
      {
        path: 'review',
        name: 'AdminReview',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminReview.vue'),
        meta: {
          login: true,
          pageAccess: 'review'
        }
      },
      {
        path: 'business_review',
        name: 'AdminBReview',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminBReview.vue'),
        meta: {
          login: true,
          pageAccess: 'review'
        }
      },
      {
        path: 'review/:id',
        name: 'AdminReviewInfo',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminReviewInfo.vue'),
        meta: {
          login: true,
          pageAccess: 'review'
        }
      },
      {
        path: 'business_review/:id',
        name: 'AdminBReviewInfo',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminBReviewInfo.vue'),
        meta: {
          login: true,
          pageAccess: 'review'
        }
      },
      {
        path: 'post',
        name: 'AdminPost',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminPost.vue'),
        meta: {
          login: true,
          pageAccess: 'post'
        }
      },
      {
        path: 'links',
        name: 'AdminLinks',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminLinks.vue'),
        meta: {
          login: true,
          pageAccess: 'post'
        }
      },
      {
        path: 'tag',
        name: 'AdminTag',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminTag.vue'),
        meta: {
          login: true,
          pageAccess: 'tag'
        }
      },
      {
        path: 'certificate',
        name: 'AdminCertificate',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminCertificate.vue'),
        meta: {
          login: true,
          pageAccess: 'certificate'
        }
      },
      {
        path: 'esim_order',
        name: 'AdminEsimOrder',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminEsimOrder.vue'),
        meta: {
          login: true,
          pageAccess: 'esim_order'
        }
      },
      {
        path: 'tour',
        name: 'AdminTour',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminTour.vue'),
        meta: {
          login: true,
          pageAccess: 'tour'
        }
      },
      {
        path: 'member/:id',
        name: 'AdminMemberInfo',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminMemberInfo.vue'),
        meta: {
          login: true,
          pageAccess: 'member'
        }
      },
      {
        path: 'business_member/:id',
        name: 'AdminBMemberInfo',
        component: () => import(/* webpackChunkName: "admin" */ '../views/AdminBMemberInfo.vue'),
        meta: {
          login: true,
          pageAccess: 'member'
        }
      }
    ]
  },

  // 企業管理後台
  {
    path: '/business-admin',
    component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdmin.vue'),
    meta: {
      login: true
    },
    children: [
      {
        path: '',
        // name: 'BAdmin',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminWelcom.vue'),
        meta: {
          login: true
        }
      },
      {
        path: 'tour',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminTour.vue'),
        meta: {
          login: true
          // accessB: 'ti3'
        }
      },
      {
        path: 'event',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminEvent.vue'),
        meta: {
          login: true
          // accessB: 'ti3'
        }
      },
      {
        path: 'event/:id',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminEvent.vue'),
        meta: {
          login: true
          // accessB: 'ti3'
        }
      },
      {
        path: 'hotel',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminHotel.vue'),
        meta: {
          login: true
          // accessB: 'ti1'
        }
      },
      {
        path: 'quotation',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminQuotation.vue'),
        meta: {
          login: true
          // accessB: 'ti1'
        }
      },
      {
        path: 'transportation',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminTransportation.vue'),
        meta: {
          login: true
          // accessB: 'ti1'
        }
      },
      {
        path: 'product',
        component: () => import(/* webpackChunkName: "BAdmin" */ '../views/BAdminProduct.vue'),
        meta: {
          login: true
          // accessB: 'ti1'
        }
      }
    ]
  },

  {
    path: '/vueintro',
    name: 'vueIntroStep',
    component: () => import(/* webpackChunkName: "vueIntroStep" */ '../views/vueIntroStep.vue')
  },
  {
    path: '/vtour',
    name: 'vTour',
    component: () => import(/* webpackChunkName: "vTour" */ '../views/vTour.vue')
  },

  // 導覽列
  {
    path: '/event',
    name: 'EsgEvent',
    component: () => import(/* webpackChunkName: "EsgEvent" */ '../views/EsgEvent.vue')
  },
  {
    path: '/event/:id',
    name: 'EsgEventPage',
    component: () => import(/* webpackChunkName: "EsgEventPage" */ '../views/EsgEventPage.vue')
  },
  {
    path: '/travel',
    name: 'EsgTravel',
    component: () => import(/* webpackChunkName: "EsgTravel" */ '../views/EsgTravel.vue')
  },
  {
    path: '/travel/:id',
    name: 'EsgTravelPage',
    component: () => import(/* webpackChunkName: "EsgTravelPage" */ '../views/EsgTravelPage.vue')
  },
  {
    path: '/product',
    name: 'EsgProduct',
    component: () => import(/* webpackChunkName: "EsgProduct" */ '../views/EsgProduct.vue')
  },
  {
    path: '/product/:id',
    name: 'EsgProductPage',
    component: () => import(/* webpackChunkName: "EsgProductPage" */ '../views/EsgProductPage.vue')
  },
  {
    path: '/news',
    name: 'EsgNews',
    component: () => import(/* webpackChunkName: "EsgNews" */ '../views/EsgNews.vue')
  },
  {
    path: '/news/:id',
    name: 'EsgNewsPage',
    component: () => import(/* webpackChunkName: "EsgNewsPage" */ '../views/EsgNewsPage.vue')
  },
  {
    path: '/about',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/board',
    name: 'BoardOfSupervisors',
    component: () => import(/* webpackChunkName: "BoardOfSupervisors" */ '../views/BoardOfSupervisors.vue')
  },
  {
    path: '/board/:id',
    name: 'BoardOfSupervisorsModal',
    component: () => import(/* webpackChunkName: "BoardOfSupervisorsModal" */ '../views/BoardOfSupervisors.vue')
  },
  {
    path: '/links',
    name: 'FriendLinks',
    component: () => import(/* webpackChunkName: "FriendLinks" */ '../views/FriendLinks.vue')
  },
  {
    path: '/certificate',
    name: 'EsgCertificate',
    component: () => import(/* webpackChunkName: "EsgCertificate" */ '../views/EsgCertificate.vue')
  },
  // 旅費計算
  {
    path: '/pricecalculate',
    name: 'PriceCalculation',
    component: () => import(/* webpackChunkName: "PriceCalculation" */ '../views/PriceCalculation.vue')
    // component: () => import(/* webpackChunkName: "PriceCal" */ '../views/PriceCal.vue')
  },
  {
    path: '/pricecalculatetest',
    name: 'PriceCalculationTest',
    component: () => import(/* webpackChunkName: "PriceCalculation" */ '../views/PriceCalculationTest.vue')
  },

  // 合作夥伴
  {
    path: '/partner',
    name: 'EsgPartner',
    component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgPartner.vue'),
    children: [
      {
        path: 'guide',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgGuide.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'business',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgBusiness.vue')
      },
      {
        path: 'travel-agency',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgTravelAgency.vue')
      },
      {
        path: 'hotel-industry',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgBHotel.vue')
      },
      {
        path: 'tourist-attraction',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgBAttraction.vue')
      },
      {
        path: 'transportation-operator',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgBTransport.vue')
      },
      {
        path: 'restaurant-industry',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgBRestaurant.vue')
      },
      {
        path: 'gift-shop',
        component: () => import(/* webpackChunkName: "EsgPartner" */ '../views/EsgBGiftshop.vue')
      }
    ]
  },
  // {
  //   path: '/business',
  //   name: 'EsgBusiness',
  //   component: () => import(/* webpackChunkName: "EsgBusiness" */ '../views/EsgBusiness.vue')
  // },
  {
    path: '/partner/guide/:id',
    name: 'EsgGuideInfo',
    component: () => import(/* webpackChunkName: "EsgGuideInfo" */ '../views/EsgGuideInfo.vue')
  },
  {
    path: '/partner/business/:id',
    name: 'EsgBusinessInfo',
    component: () => import(/* webpackChunkName: "EsgBusinessInfo" */ '../views/EsgBusinessInfo.vue')
  },
  {
    path: '/partner/travel-agency/:id',
    name: 'EsgTravelAgencyInfo',
    component: () => import(/* webpackChunkName: "EsgTravelAgencyInfo" */ '../views/EsgTravelAgencyInfo.vue')
  },

  // 忘記密碼
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import(/* webpackChunkName: "forgot" */ '../views/ForgotPage.vue')
  },
  {
    path: '/getpassword/:token',
    name: 'getPSW',
    component: () => import(/* webpackChunkName: "getPSW" */ '../views/getPSW.vue')
  },

  // 改email、寄認證信、推薦人、加小組、匯款通知
  {
    path: '/payment-confirm/:token',
    name: 'PaymentConfirm',
    component: () => import(/* webpackChunkName: "PaymentConfirm" */ '../views/PaymentConfirm.vue')
  },
  {
    path: '/email-change',
    name: 'EmailChange',
    component: () => import(/* webpackChunkName: "EmailChange" */ '../views/EmailChange.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '/send-verify',
    name: 'SendVerify',
    component: () => import(/* webpackChunkName: "SendVerify" */ '../views/SendVerify.vue'),
    meta: {
      login: true
    }
  },
  {
    path: '/email-verify/:token',
    name: 'EmailVerify',
    component: () => import(/* webpackChunkName: "EmailVerify" */ '../views/EmailVerify.vue')
  },
  {
    path: '/referrer/:token',
    name: 'ReferrerPage',
    component: () => import(/* webpackChunkName: "ReferrerPage" */ '../views/ReferrerPage.vue')
  },
  {
    path: '/joinguidegroup/:token',
    name: 'JoinGuideGroup',
    component: () => import(/* webpackChunkName: "JoinGuideGroup" */ '../views/JoinGuideGroup.vue')
  },
  {
    path: '/register/:token',
    name: 'RegisterPage',
    component: () => import(/* webpackChunkName: "RegisterPage" */ '../views/RegisterPage.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: '/',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // await store.dispatch('user/checkJWT')
  // const user = store.getters['user/user']
  //  && !to.meta.pageAccess

  // 進入會員中心前先檢查是否登入
  if (to.path === '/member' || to.path === '/business-member') {
    await store.dispatch('user/checkJWT', 'getInfo')
    const user = store.getters['user/user']
    // console.log(user)
    if (user.isLogin) {
      next()
    } else {
      alert('進入會員中心前請先登入')
      next('/')
    }
  } else

  // 進入需登入的頁面前先檢查是否登入
  if (to.meta.login) {
    await store.dispatch('user/checkJWT')
    const user = store.getters['user/user']

    if (!user.isLogin) {
      next('/')
      alert('請先登入')
    } else

    // 企業管理後台
    // !accessB是否需要
    if (to.meta.accessB) {
      console.log(user.business.industry)
      if (user.business.industry === to.meta.accessB) {
        next()
      } else return false
    } else
    // 如果該頁面需要權限，且使用者有相關權限才能進入
    if (to.meta.pageAccess) {
      // console.log('pageAccess')
      // console.log(user.page)
      // 後台首頁沒有相對應的參數，故設定有showBackstage才能進入後台首頁
      if (to.meta.pageAccess === 'adminIndex') {
        if (!user.showBackstage) {
          // console.log('NO')
          next('/')
        } else next()
      } else { // 後台其他頁，如果使用者有進入該頁面的權限才能進入
        for (const key in user.page) {
          if (key === to.meta.pageAccess && user.page[key]) {
            // console.log('OK')
            next()
            break
            //  if (key === to.meta.pageAccess && !user.page[key])
          } else if (key === to.meta.pageAccess && !user.page[key]) {
            console.log('沒有權限')
            alert('沒有權限')
            next('/')
          }
        }
      }
    } else next()
  } else {
    // console.log('routerindex')
    next()
  }

  // 設定需登入的頁面
  // if (to.meta.login && !user.isLogin) {
  //   console.log(to.meta.login)
  //   next('/')
  //   alert('請先登入')
  // } else {
  //   next()
  // }

  // 設定已登入者不能進入忘記密碼頁
  if (to.path === '/forgot' || to.name === 'getPSW') {
    const user = store.getters['user/user']
    if (user.isLogin) {
      next('/')
      alert('已登入')
    }
  }
})
router.afterEach((to, from, next) => {
  if (to.path === '/') window.scrollTo(0, 0)
})

export default router
