<template>
  <b-breadcrumb id="breadcrumb" :items="breadItems" class="container"></b-breadcrumb>
</template>

<script>
export default {
  props: {
    breadItems: {
      required: true
    }
  },
  name: 'BreadCrumb',
  data () {
    return {
    }
  }
}
</script>
<style lang="scss">
#breadcrumb {
  background-color: #fff !important;
  color: #6b793d;
  margin-bottom: 0;
}
</style>
