export default () => {
  return {
    isPersonUser: true,
    // 企業會員
    business: {},
    jwt: '',
    // isAdmin: false,
    // group: '',
    page: {
      // member: false,
      // post: false,
      // tour: false,
      // hotel: false,
      // spot: false,
      // other: false,
      // access: false,
      // page: false
    },
    check_email: '', // 註冊EMAIL是否已發出驗證信
    premium_at: '',
    referrer: '',
    referrer_id: '0',
    remember_referrer_id: '',
    remember_referrer_name: '',
    remember_group_name: '',
    email_verified: false,
    profile_photo_path: '',
    // 導覽列是否顯示管理後台
    showBackstage: false,
    id: '',
    username: '',
    name: '',
    email: '',
    birthday: '',
    birthD: '',
    birthM: '',
    birthY: '',
    gender: '',
    lineId: '',
    referrer_data: [],
    group_leader: [],
    group_member: [],
    guideGroupId: '',
    guideGroup: '',
    guideData: {
      images: [],
      // 1-待審核 2-退回 3-成功
      status: 0,
      nick_name: '',
      introduce: '',
      service: '',
      id_number: '',
      phone: '',
      laborInsurance: '',
      employer: '',
      esgGuide: '',
      esgTraining: '',
      esgTrainingUnit: '',
      esgCertified: '',
      esgCertifiedUnit: '',
      roles: {
        role1: false,
        role2: false,
        role3: false,
        role4: false
      },
      langSelected1: {
        l_cmn: false,
        l_eng: false,
        l_jpn: false,
        l_fra: false,
        l_deu: false,
        l_spa: false,
        l_kor: false,
        l_tha: false,
        l_ara: false,
        l_rus: false,
        l_ita: false,
        l_vie: false,
        l_ind: false,
        l_may: false,
        l_tur: false
      },
      langSelected2: {
        l_cmn: false,
        l_eng: false,
        l_jpn: false,
        l_fra: false,
        l_deu: false,
        l_spa: false,
        l_kor: false,
        l_tha: false,
        l_ara: false,
        l_rus: false,
        l_ita: false,
        l_vie: false,
        l_ind: false,
        l_may: false,
        l_tur: false
      },
      // 區域
      role1Area: {
        north: { name: '北台灣', val: false },
        middle: { name: '中台灣', val: false },
        south: { name: '南台灣', val: false },
        east: { name: '東台灣', val: false }
      },
      // 機場
      role2Area: {
        tsa: { name: '台北', val: false },
        tpe: { name: '桃園', val: false },
        txg: { name: '台中', val: false },
        khh: { name: '高雄', val: false }
      },
      // 專長
      guideTag1: {},
      guideTag2: {}
    }
  }
}
