<template>
  <div id="home">
    <!-- <div class="d-flex banner">
      <a href="/pricecalculate">
        <b-img :src='require("@/assets/img/banner.jpg")' alt="入境台灣旅費測算" title="入境台灣旅費測算"></b-img>
        <h4 class="banner_title">入境台灣旅費測算</h4>
      </a>
    </div> -->

    <div class="px-4 py-5 bg_gray d-flex justify-content-center" style="background: url('https://esg888.com.tw/img/banner.b6ef64b3.jpg') center/cover;">
      <!-- <a href="https://tstar888.com/geaw/esim" target="_blank">
      <div class="bg-white home_banner rounded p-4">
          <h5 class="text_green mb-4 mt-3"><b>出國上網新選擇 - eSIM 免換卡更方便！</b></h5>
          <p>日韓歐美、多國套餐，涵蓋各國的上網方案，點擊查看 eSIM 方案</p>
      </div>
      </a> -->
      <b-container>
        <b-row>
          <b-col md="6" class="mb-4 mb-md-0">
            <a href="/pricecalculate">
              <div class="bg-white home_banner rounded p-4">
                <h5 class="orange_title mb-4 mt-3">
                  <b-icon-calculator rotat="20" font-scale="1.3"></b-icon-calculator><b> 一站式簡易計算，輕鬆掌握台灣之旅開銷 </b>
                </h5>
                <p>從餐食、交通、住宿到景點的全方位計算，輕鬆安排您的旅程！</p>
              </div>
            </a>
          </b-col>
          <b-col md="6" class="mb-4 mb-md-0">
            <a href="https://tstar888.com/geaw/esim">
              <div class="bg-white home_banner rounded p-4">
                  <h5 class="green_title mb-4 mt-3">
                    <b-icon-sim font-scale="1.3"></b-icon-sim><b> 出國上網新選擇 - eSIM 免換卡更方便！</b></h5>
                  <p>日韓歐美、多國套餐，涵蓋各國的上網方案，點擊查看 eSIM 方案 </p>
              </div>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- <b-carousel
      id="carousel-fade"
      style="text-shadow: 0px 0px 2px #000"
      fade
      img-width="1024"
      img-height="480"
      >
      <b-carousel-slide
        caption="台灣ESG旅遊推廣協會"
        :img-src='require("@/assets/img/banner.jpg")'
      ></b-carousel-slide>
    </b-carousel> -->

    <!-- 導遊、領隊合作夥伴 -->
    <section class="">
      <h4 class="h4title">導遊、領隊</h4>
      <b class="mb-4 brandtxt">合作夥伴</b>
      <div class="subtxt text-right mr-2 mt-3 mb-2">
        <router-link to='/partner/guide' class="round_btn_bk">
          看全部<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2" />
        </router-link>
      </div>

      <!-- 等待載入時的畫面 -->
      <div v-if="loading" class="text-center d-flex justify-content-around mt-3 px-5">
        <b-skeleton width="30%" height="100px" class="mb-3"></b-skeleton>
        <b-skeleton width="30%" height="100px" class="mb-3"></b-skeleton>
        <b-skeleton width="30%" height="100px" class="mb-3"></b-skeleton>
      </div>
      <!-- 導領輪播卡片 -->
      <div class="example-3d">
        <!-- v-if="guideData.length>0" 解決coverflow和loop衝突 -->
        <swiper class="swiper text-left" :options="swiperOption" v-if="guideData.length>0">
            <swiper-slide v-for="guide in guideData" :key="guide.id"
              class="border d-block bg-white">
          <router-link :to="'/partner/guide/'+guide.id">
              <div v-if="guide.esgGuide" class="esg_badge text-center">ESG認證</div>
              <div class="d-flex">
                <b-avatar
                  :src="'https://esg888.com.tw/src/assets/imgData/users/' + guide.user.profile_photo_path + '/avatar.jpg'"
                  size="5em" :alt="'esg、導遊領隊、'+guide.nick_name">
                </b-avatar>
                <div class="ml-4">
                  <div class=""><strong>{{ guide.nick_name }}</strong></div>
                  <div>
                    <b-badge class="mr-1 role_bagde" v-if="guide.roles && guide.roles.role1">
                      導遊
                    </b-badge>
                    <span v-if="guide.langSelected1.l_cmn"><b-badge pill variant="light">華語</b-badge></span>
                    <span v-if="guide.langSelected1.l_eng"><b-badge pill variant="light">英語</b-badge></span>
                    <span v-if="guide.langSelected1.l_jpn"><b-badge pill variant="light">日語</b-badge></span>
                    <span v-if="guide.langSelected1.l_fra"><b-badge pill variant="light">法語</b-badge></span>
                    <span v-if="guide.langSelected1.l_deu"><b-badge pill variant="light">德語</b-badge></span>
                    <span v-if="guide.langSelected1.l_spa"><b-badge pill variant="light">西班牙語</b-badge></span>
                    <span v-if="guide.langSelected1.l_kor"><b-badge pill variant="light">韓語</b-badge></span>
                    <span v-if="guide.langSelected1.l_tha"><b-badge pill variant="light">泰語</b-badge></span>
                    <span v-if="guide.langSelected1.l_ara"><b-badge pill variant="light">阿拉伯語</b-badge></span>
                    <span v-if="guide.langSelected1.l_rus"><b-badge pill variant="light">俄語</b-badge></span>
                    <span v-if="guide.langSelected1.l_ita"><b-badge pill variant="light">義大利語</b-badge></span>
                    <span v-if="guide.langSelected1.l_vie"><b-badge pill variant="light">越南語</b-badge></span>
                    <span v-if="guide.langSelected1.l_ind"><b-badge pill variant="light">印尼語</b-badge></span>
                    <span v-if="guide.langSelected1.l_may"><b-badge pill variant="light">馬來語</b-badge></span>
                    <span v-if="guide.langSelected1.l_tur"><b-badge pill variant="light">土耳其語</b-badge></span>
                  </div>
                  <div>
                    <b-badge class="mr-1 role_bagde" v-if="guide.roles && guide.roles.role2">
                      領隊
                    </b-badge>
                    <span v-if="guide.langSelected2.l_cmn"><b-badge pill variant="light">華語</b-badge></span>
                    <span v-if="guide.langSelected2.l_eng"><b-badge pill variant="light">英語</b-badge></span>
                    <span v-if="guide.langSelected2.l_jpn"><b-badge pill variant="light">日語</b-badge></span>
                    <span v-if="guide.langSelected2.l_fra"><b-badge pill variant="light">法語</b-badge></span>
                    <span v-if="guide.langSelected2.l_deu"><b-badge pill variant="light">德語</b-badge></span>
                    <span v-if="guide.langSelected2.l_spa"><b-badge pill variant="light">西班牙語</b-badge></span>
                    <span v-if="guide.langSelected2.l_kor"><b-badge pill variant="light">韓語</b-badge></span>
                    <span v-if="guide.langSelected2.l_tha"><b-badge pill variant="light">泰語</b-badge></span>
                    <span v-if="guide.langSelected2.l_ara"><b-badge pill variant="light">阿拉伯語</b-badge></span>
                    <span v-if="guide.langSelected2.l_rus"><b-badge pill variant="light">俄語</b-badge></span>
                    <span v-if="guide.langSelected2.l_ita"><b-badge pill variant="light">義大利語</b-badge></span>
                    <span v-if="guide.langSelected2.l_vie"><b-badge pill variant="light">越南語</b-badge></span>
                    <span v-if="guide.langSelected2.l_ind"><b-badge pill variant="light">印尼語</b-badge></span>
                    <span v-if="guide.langSelected2.l_may"><b-badge pill variant="light">馬來語</b-badge></span>
                    <span v-if="guide.langSelected2.l_tur"><b-badge pill variant="light">土耳其語</b-badge></span>
                  </div>
                </div>
              </div>
              <div class="body mt-1">
                <div v-if="guide.tag1.length>0" class="mt-2">
                  <div class="brandtxt subtxt">導遊特殊帶團專業：</div>
                  <span v-for="(tag, index) in guide.tag1" :key="index">
                    {{ index === guide.tag1.length-1 ? tag : tag + '、'}}
                  </span>
                </div>
                <div v-if="guide.area1.length>0" class="mt-2">
                  <div class="brandtxt subtxt">導遊可接團區域：</div>
                  <span v-for="(area, index) in guide.area1" :key="index">
                    {{ index === guide.area1.length-1 ? area : area + '、'}}
                  </span>
                </div>
                <div v-if="guide.tag2.length>0" class="mt-2">
                  <div class="brandtxt subtxt">領隊專業區域：</div>
                  <span v-for="(tag, index) in guide.tag2" :key="index">
                    {{ index === guide.tag2.length-1 ? tag : tag + '、'}}
                  </span>
                </div>
                <div v-if="guide.area2.length>0" class="mt-2">
                  <div class="brandtxt subtxt">領隊出團機場：</div>
                  <span v-for="(area, index) in guide.area2" :key="index">
                    {{ index === guide.area2.length-1 ? area : area + '、'}}
                  </span>
                </div>
                <hr>
                <div class="mt-2 subtxt">服務項目<br></div>
                <div class="">{{ guide.service }}</div>
                <div class="subtxt mt-2">自我介紹<br></div>
                <div>{{ guide.introduce }}</div>
              </div>
          </router-link>
            </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </section>

    <!-- 最新消息 -->
    <section class="d-block news_area">
      <h4 class="h4title mb-4">最新消息</h4>
      <div class="subtxt text-right mr-2">
        <router-link to='/news' class="round_btn_bk">
          看全部<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2" />
        </router-link>
      </div>
      <b-row class="mt-3" v-if="news.length>0">
        <b-col md="4" v-for="(n, index) in news" :key="index" class="d-none d-md-block">
          <div class="ProductCard">
            <router-link :to='"/news/"+n.id'>
              <b-card
                img-top
                tag="article"
                class="mb-3 p-2"
              >
                <div class="date mb-1">{{ n.date }}</div>
                <div class="card-title">{{ n.title }}</div>
                <b-card-text class="px-2">
                  <div v-html="n.detail"></div>
                </b-card-text>
                <b-button class="read_btn">閱讀更多</b-button>
              </b-card>
            </router-link>
          </div>
        </b-col>
        <b-col md="4" class="d-md-none">
          <div class="ProductCard">
            <router-link :to='"/news/"+news[0].id'>
              <b-card
                img-top
                tag="article"
                class="mb-5 p-2"
              >
                <div class="date mb-1">{{ news[0].date }}</div>
                <div class="card-title">{{ news[0].title }}</div>
                <b-card-text class="px-2">
                  <div v-html="news[0].detail"></div>
                </b-card-text>
                <b-button class="read_btn">閱讀更多</b-button>
              </b-card>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </section>

    <!-- 關於我們 -->
    <AboutUs></AboutUs>
    <!-- <section class="d-none">
      <h4 class="h4title mb-4">會員方案</h4>
        <div class="my-3">導遊、領隊</div>
      <b-table
        outlined
        borderless
        :items="items"
        :fields="fields"
        >
        <template #cell(member)="data">
          {{ data.item.member===true ? 'v' : !data.item.member ? 'x' : data.item.member}}
        </template>
        <template #cell(premium)="data">
          {{ data.item.premium===true ? 'v' : !data.item.premium ? 'x' : data.item.premium}}
        </template>
      </b-table>
    </section> -->

    <!-- <section>
      <b-container id="section1" class="text-left">
        <div class="title_part">
          <h4>什麼是ESG？</h4>
          <h6>它對企業經營及品牌管理究竟會帶來什麼影響？</h6>
        </div>
        <b-row class="card_area">
          <b-col sm="4">
            <div class="card1">
              <div>ESG，代表Environmental環境永續、Social社會參與，及Governance公司治理的三個企業經營層面。根據維基百科11，ESG是「公司治理」對「社會」及「環境」所展現的良心與責任，是一套完整衡量企業永續性的指標。</div>
            </div>
          </b-col>
          <b-col sm="4">
            <div class="card1">
              <div>在環境永續的層面，關注的是公司如何應對「氣候變遷」及「永續經營」，包括溫室氣體排放、資源消耗（如水）、環境污染、森林砍伐及野生動物保護等。因此公司面對的課題與風險，就是處理土地污染、廢水排放、有毒物質管理，以及是否遵守政府的環境法規。
              在環境永續的評量，也產生了很多新的指標及認證機構，如RE100、EP100、EV100、SBTi、TCFD及B Corp等，企業及消費者都必須瞭解，未來這些指標也可能成為認證品牌，並為品牌ESG背書的標籤，成為品牌信賴資產的一部分。
              </div>
            </div>
          </b-col>
          <b-col sm="4">
            <div class="card1">
              <div>在社會關懷的層面，關注的是公司如何處理「利害關係人」的利益關係，包括社會的多元化、人權問題，以及消費者的保護。例如公司是否僱用童工？公司是否捐獻利潤的固定比率給社區或弱勢團體？是否支薪鼓勵員工當志工貢獻社會？
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section> -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  metaInfo () {
    const titleTemplate = this.titleTemplate
    const meta = this.metaData
    return { meta, titleTemplate }
  },
  name: 'HomeView',
  data () {
    return {
      loading: true,
      guideData: [],
      guideTag1: [],
      guideTag2: [],
      swiperOption: {
        autoplay: {
          delay: 4500,
          disableOnInteraction: false
        },
        loop: true,
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true
        },
        pagination: {
          el: '.swiper-pagination'
        }
      },
      news: [],
      fields: [
        { key: 'name', label: '', class: 'td' },
        { key: 'member', label: '免費會員', class: 'td', variant: 'light' },
        { key: 'premium', label: '付費會員', class: 'td', variant: 'primary' }
      ],
      items: [
        { member: '50字', premium: '500字', name: '自我介紹' }, // 自介
        { member: '20字', premium: '50字', name: '服務項目' },
        { member: '1張', premium: '10張', name: '相簿' },
        { member: false, premium: true, name: '行事曆' }
      ],
      metaData: [{
        vmid: 'keywords',
        name: 'keywords',
        content: 'ESG'
      },
      {
        vmid: 'description',
        name: 'description',
        content: '台灣ESG旅遊推廣協會，協會願景：永續台灣ESG旅遊產業、營造台灣ESG旅遊環境、接軌ESG旅遊國際交流、提升ESG典範旅遊發展、推動ESG旅遊產業創新、落實ESG旅遊社會責任。發展策略：建立ESG旅遊產業認證標章、建置ESG旅遊知識網絡平台、研發專業ESG旅遊課程、推動旅遊業者通過綠色環保標章、辦理ESG旅宿、旅行社、車隊與領隊導遊認證課程、辦理旅遊業者碳盤查、碳足跡內部稽核員訓練。'
      }],
      titleTemplate: null
    }
  },
  methods: {
  },
  async created () {
    // 讀取導領標籤
    try {
      const { data } = await this.api.post('php_modules/auth-file/tag.php?type=r1&category=guide1')
      // console.log(data)
      this.guideTag1 = JSON.parse(JSON.stringify(data.data))
    } catch (error) {
      alert(error)
    }
    try {
      const { data } = await this.api.post('php_modules/auth-file/tag.php?type=r1&category=guide2')
      // console.log(data)
      this.guideTag2 = JSON.parse(JSON.stringify(data.data))
    } catch (error) {
      alert(error)
    }

    // 讀取導領
    try {
      // const { data } = await this.api.post('php_modules/auth-file/guide.php?type=r&id=4')
      const { data } = await this.api.post('php_modules/auth-file/guide.php?type=r&status=3')
      // console.log(data)
      this.dataLength = data.data.length
      this.guideData = JSON.parse(JSON.stringify(data.data))
      this.loading = false
      this.guideData.forEach(guide => {
        guide.tag1 = []
        for (const tag in guide.guideTag1) {
          if (guide.guideTag1[tag].val) guide.tag1.push(guide.guideTag1[tag].title)
        }
      })

      this.guideData.forEach(guide => {
        guide.tag2 = []
        for (const tag in guide.guideTag2) {
          if (guide.guideTag2[tag].val) guide.tag2.push(guide.guideTag2[tag].title)
        }
      })

      this.guideData.forEach(guide => {
        guide.area1 = []
        for (const area in guide.role1Area) {
          if (guide.role1Area[area].val) guide.area1.push(guide.role1Area[area].name)
        }
      })
      this.guideData.forEach(guide => {
        guide.area2 = []
        for (const area in guide.role2Area) {
          if (guide.role2Area[area].val) guide.area2.push(guide.role2Area[area].name)
        }
      })
    } catch (error) {
      alert(error)
    }
    // 讀取前三篇最新消息
    try {
      const { data } = await this.api.post('php_modules/auth-file/post.php?type=r')
      // console.log(data)
      this.news = data.data.slice(0, 3)
    } catch (error) {
      alert(error)
    }
  }
  // mounted () {
  //   this.$nextTick(() => {
  //     const tour = this.$shepherd({
  //       useModalOverlay: true
  //     })

  //     tour.addStep({
  //       attachTo: { element: this.$refs.test, on: 'top' },
  //       text: 'Test'
  //     })

  //     tour.start()
  //   })
  // }
}
</script>

<style lang="scss">
  .card1 {
    border-radius: 1.5rem;
    background-color: var(--mytheme);
    background-color: #fff;
    padding: 2rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
    line-height: 1.8rem;
  }
  #home {
    .home_banner {
      box-shadow: var(--shadow);
      &:hover h5 {
        transform: scale(1.03);
        transition: .3s;
      }
    }
  .role_bagde {
    color: #fff;
    background-color: var(--mytheme2);
  }
  .esg_badge {
    z-index: 99;
  }
  .example-3d {
    width: 100%;
    // height: 430px;
  }
  .swiper {
    padding: 20px 7px 40px;
    // padding-bottom: 40px;
    // height: 100%;
    width: 100%;
    .swiper-slide {
      padding: 2rem 1.25rem;
      display: flex;
      // width: 50%;
      // justify-content: center;
      // align-items: center;
      // height: 200px;
      @media (min-width:768px) {
        width: 30%;
      }
    }
    .swiper-pagination-bullet-active {
      background-color: var(--mytheme2);
    }
    .body {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
    .banner {
      position: relative;
      img {
        width: 100%;
        background-size: cover;
      }
    }
    .banner_title {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      margin: 0 auto;
      color: #fff;
      text-shadow:  0px 0px 2px #000;
      font-weight: bold;
      word-break: keep-all;
    }
    table {
      color: rgb(83, 83, 83);
      .table-light {
        background-color: rgb(235, 235, 235);
      }
    }
    td[aria-colindex="1"] {
      // background-color: antiquewhite;
      font-weight: 600;
      // color: rgb(121, 121, 121);
    }
    .carousel-item {
      // height: 480px;
    }
    // background-color: var(--myprimary);
    #section1 {
      h4 {
        letter-spacing: 2px;
      }
      h4,h6 {
        font-weight: bold;
      }
    }
    .news_area {
      background-color: rgb(248, 248, 248);
      .card {
        border: none;
        text-align: left;
        line-height: 1.7em;
        box-shadow: 0px 5px 15px #e2e2e2d5;
        &:hover .card-title {
          color: var(--mytheme2);
        }
      }
      .date {
        font-size: 14px;
        color: #838383;
      }
      .card-title {
        font-weight: 600;
        font-size: 1.2rem;
        letter-spacing: 1px;
        // margin-bottom: 8px;
        transition: .5s;
      }
      .card-text {
        position: relative;
        // max-height: 50px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        margin-bottom: 5px;
      }
    }
  }
</style>
