import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import './assets/scss/style.scss'
import './plugins/axios.js'
import mixin from './mixin.js'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import ImgInputer from 'vue-img-inputer'
import 'vue-img-inputer/dist/index.css'
import BreadCrumb from './components/BreadCrumb.vue'
import AboutUs from './views/AboutUs.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBan, faAngleUp, faArrowRight, faCircleUser, faArrowRightFromBracket, faAngleDown, faMagnifyingGlass, faAnglesUp, faAnglesDown, faSpinner, faTriangleExclamation, faWindowRestore, faCircleCheck } from '@fortawesome/free-solid-svg-icons'

import Vue2Editor from 'vue2-editor'

import VueMeta from 'vue-meta'
import VueEasyLightbox from 'vue-easy-lightbox'
import VueClipboard from 'vue-clipboard2'
import VCalendar from 'v-calendar'
import 'animate.css'
import VueIntroStep from 'vue-intro-step'

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.component('vue-intro-step', VueIntroStep)
Vue.use(VCalendar)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VueEasyLightbox)
Vue.use(VueMeta)

Vue.use(Vue2Editor)
/* add icons to the library */
library.add(faBan, faAngleUp, faArrowRight, faCircleUser, faArrowRightFromBracket, faAngleDown, faMagnifyingGlass, faAnglesUp, faAnglesDown, faSpinner, faTriangleExclamation, faWindowRestore, faCircleCheck)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('ImgInputer', ImgInputer)
Vue.component('BreadCrumb', BreadCrumb)
Vue.component('AboutUs', AboutUs)
Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.mixin(mixin)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
