<template>
  <div id="app">
    <NavbarPart/>
    <router-view/>
    <FooterPart/>
    <div class="back_top" title="回頂部" @click="scrollTop" :class="hide">
        <font-awesome-icon class="fa_icon" icon="fa-solid fa-angle-up"></font-awesome-icon>
    </div>
  </div>
</template>

<script>
import FooterPart from '@/components/FooterPart.vue'
import NavbarPart from '@/components/NavBar.vue'

export default {
  components: {
    FooterPart,
    NavbarPart
  },
  data () {
    return {
      // isShow: false,
      hide: 'hide'
    }
  },
  metaInfo: {
    title: '台灣ESG旅遊推廣協會',
    titleTemplate: '%s | 台灣ESG旅遊推廣協會'
    // description: ''
  },
  methods: {
    scrollTop () {
      // console.log(window.pageYOffset)
      window.scrollTo(0, 0)
    },
    showToTop () {
      if (window.pageYOffset > 100) {
        this.hide = ''
      } else {
        this.hide = 'hide'
      }
    }
  },
  async created () {
    // console.log('app created')
  },
  mounted () {
    window.addEventListener('scroll', this.showToTop)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.showToTop)
  }
}

</script>

<style lang="scss">
// @import url(@/assets/scss/style.scss);
#app {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  .back_top {
    z-index: 99;
    position: fixed;
    width: 40px;
    height: 40px;
    background-color: var(--mytheme2);
    color: #fff;
    bottom: 60px;
    right: 10px;
    border-radius: 50px;
    line-height: 40px;
    text-align: center;
    font-weight: 600;
    opacity: .8;
    font-size: 1.3rem;
    transform: translateY(0%);
    transition: 0.2s linear;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 5px 5px#7c8d448c;
    }
    &.hide {
      opacity: 0;
      transform: translateY(250%);
    }
  }
}

nav {
  padding: 30px;

  a {
    // font-weight: bold;
    // color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }

  // .v-step__header {
  //   border-radius: .5em .5em 0 0 !important;
  // }
  // .v-step {
  //   border-radius: .5em !important;
  // }
  // .v-step__button {
  //   padding: 5px 15px !important;
  //   border-radius: 50px !important;
  // }
  // .v-tour__target--highlighted {
  //   box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
  // }
}
</style>
