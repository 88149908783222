<template>
  <div id="about">
    <b-container>
      <div class="subtxt text-right mr-2 my-4">
        <router-link to='/board' class="round_btn_bk">
          理監事會<font-awesome-icon icon="fa-solid fa-arrow-right" class="ml-2" />
        </router-link>
      </div>
      <h4 class="h4title mb-4">關於我們</h4>
      <div class="content text-left py-4">
        <b-row>
          <b-col cols="12" class="d-none flex-wrap vision justify-content-center">
            <div>永續台灣ESG旅遊產業</div>
            <div>營造台灣ESG旅遊環境</div>
            <div>接軌ESG旅遊國際交流</div>
            <div>提升ESG典範旅遊發展</div>
            <div>推動ESG旅遊產業創新</div>
            <div>落實ESG旅遊社會責任</div>
          </b-col>
          <b-col md="6">
            <li class="liTitle ">協會願景</li>
            <ol>
              <li>永續台灣ESG旅遊產業</li>
              <li>營造台灣ESG旅遊環境</li>
              <li>接軌ESG旅遊國際交流</li>
              <li>提升ESG典範旅遊發展</li>
              <li>推動ESG旅遊產業創新</li>
              <li>落實ESG旅遊社會責任</li>
            </ol>
          </b-col>
          <b-col md="6">
            <li class="liTitle">發展策略</li>
            <ol>
              <li>建立ESG旅遊產業認證標章</li>
              <li>建置ESG旅遊知識網絡平台</li>
              <li>研發專業ESG旅遊課程</li>
              <li>推動旅遊業者通過綠色環保標章</li>
              <li>辦理ESG旅宿、旅行社、車隊與領隊導遊認證課程</li>
              <li>辦理旅遊業者碳盤查、碳足跡內部稽核員訓練</li>
            </ol>
          </b-col>
        </b-row>
        <div class="mt-5">
          <p>
            自1850年的工業革命以來， 因為二氧化碳的排放， 導致地球的溫度不斷上升， 也導致近幾年的極端氣侯發生的頻率及區域不斷的增加。
          </p>
          <p>
            為緩解全球變暖趨勢，聯合國在1992年通過《聯合國氣候變化框架公約》， 1997年在日本京都的大會形成關於限制溫室氣體排放《京都議定書》，：2005年2月16日《京都議定書》生效，強制減排機制為主流。COP 26 ：2021年11月12日開始，自願減排機制為主流。
          </p>
          <p>
            為響應全球2050年全球淨零排碳(Net Zero)目標， 不僅全球投資機構已將<strong class="text-danger">企業推動環境、社會及公司治理(ESG)</strong> 成果列入投資衡量指標， 知名企業也將<strong class="text-danger">供應鍊碳足跡及減碳績效</strong>列入公司採構重要指標，氣侯政策不再只是台灣的環境正義課題， 而是台灣在新的國際貿易規則中如何求生存並提高競爭力的難題。</p>
          <p>
            2021年10月29日，中華民國總統也在"2021台灣資本市場論壇" 中提出: <strong class="text-danger">持續鼓勵ESG投資， 達成金融、企業和永續發展的三贏</strong>， 2050淨零轉型是全世界的目標， 也是台灣的目標， 而身為排碳大戶的旅遊業更有責任一起扭轉全球暖化， 讓人類和萬物保有永續的生活環境， 在這共識下， 我們結合了台灣旅遊相關的主要旅行社、飯店、民宿、遊覽車等交通業者、餐廳、導遊、領隊、伴手禮店等成立了台灣ESG旅遊推廣協會。
          </p>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>

export default {
  // metaInfo () {
  //   const title = this.metaTitle
  //   // const meta = this.metaData
  //   // return { title, meta }
  //   return { title }
  // },
  data () {
    return {
      // metaData: [],
      // metaTitle: '關於我們'
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss">
#about {
  .vision {
    div {
      justify-content: center;
      font-size: 15px;
      border-radius: .25em;
      border: 1px solid #C45911;
      margin: .5em;
      padding: 1em 2em;
    }
  }
  .container {
    padding-top: 1rem;
  }
  .content {
    padding-top: 1rem;
    font-size: 17px;
    line-height: 2em;
  }
  .liTitle {
    margin-left: 1em;
    list-style: none;
    color: #C45911;
    font-weight: 600;
    font-size: 18px;
  }
  ol li {
    font-weight: 600;
  }
  @media (min-width: 768px) {
    .container {
      max-width: 700px;
      // background-color: antiquewhite;
      padding: var(--paddingMD_Y) 0rem;
    }
  }
}
</style>
