import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showGuidedTour_index: true,
    showGuidedTour_member: true,
    navName: ''
  },
  getters: {
  },
  mutations: {
    showNavName (state, name) {
      if (name === '管理') {
        state.navName = ''
      } else state.navName = ' - ' + name
    },
    changeShowGuidedTour (state, name) {
      state[name] = false
    }
  },
  actions: {
  },
  modules: {
    user
  },
  // 把VUEX的資料存在localStorage
  plugins: [createPersistedState({
    key: 'esg888com',
    paths: [
      'user.id',
      'user.jwt',
      'user.showBackstage',
      'user.email',
      'user.email_verified',
      'user.remember_referrer_id',
      'user.remember_referrer_name',
      'user.remember_group_name',
      'navName',
      'user.group_leader',
      'user.group_member',
      'user.guideGroupId',
      'user.guideGroup',
      'user.isPersonUser',
      'user.business',
      'showGuidedTour_index',
      'showGuidedTour_member',
      'user.referrer'
    ]
  })]
})
