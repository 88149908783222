export const user = (state) => {
  return {
    // 內部使用VVIP
    vvip: state.business.email === 'it1@gwls.com.twsss' || state.business.email === 'charlestsai@gwls888.com' ||
      state.email === 'it1@gwls.com.tw',
    // 內部企業會員
    idBAdmin: state.business.email === 'it1@gwls.com.twsss' ||
      state.business.email === 'charlestsai@gwls888.com' ||
      state.business.email === 'keath@gwls.com.tw',

    // Premium為付費會員
    isPremium: new Date(state.premium_at).getTime() > new Date().getTime(), // 判斷是否付費會員
    // BPremium為付費企業會員
    isBPremium: new Date(state.business.premium_at).getTime() > new Date().getTime(), // 判斷是否付費會員
    // 是否登入
    isLogin: state.jwt.length > 0,
    // 是否已建立導領資料
    guideCreated: state.guideData !== null,
    // isAdmin: state.role === 0, backstage
    ...state
  }
}
