<template>
  <b-navbar id="navbar" toggleable="md" type="light">
    <!-- 網頁導覽教學-手機 -->
    <v-tour
      name="home_tour_mobile"
      :options="tourOpt" :steps="tourStepMobile" :callbacks="myCallbacks"></v-tour>
    <!-- 網頁導覽教學-網頁 -->
    <v-tour
      name="home_tour_web"
      :options="tourOpt" :steps="tourStepWeb" :callbacks="myCallbacks"></v-tour>
    <b-modal
      id="waitModal"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer>
      <div class="my-5 mx-3 text-center">
      <h1>
        <b-icon class="my-2" icon="hourglass-bottom" animation="spin-pulse"></b-icon>
      </h1>
          請稍候
      </div>
    </b-modal>
    <b-modal
      id="loading"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer>
      <div class="my-5 mx-3 text-center">
      <h1>
        <b-icon class="my-2" icon="hourglass-bottom" animation="spin-pulse"></b-icon>
          <!-- v-if="!searchDone" -->
        <!-- <font-awesome-icon
          icon="fa-solid fa-spinner"
          class="my-2 fa-spin-pulse">
        </font-awesome-icon> -->
      </h1>
      </div>
    </b-modal>
    <b-modal
      id="modalSubmitting"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      hide-footer>
      <div class="my-5 mx-3 text-center">
      <h1>
        <font-awesome-icon icon="fa-solid fa-spinner" class="my-2 fa-spin-pulse"></font-awesome-icon>
      </h1>
          若上傳照片可能需要較久時間，請耐心稍候，謝謝！
      </div>
    </b-modal>

    <!-- 電腦版導覽列 -->
    <div class="container">
      <b-navbar-brand to="/" class="brandtxt">台灣ESG旅遊推廣協會
        <!-- <img src="@/assets/img/TWESGLOGO.png" class="img-fluid nav_img"> -->
      </b-navbar-brand>

      <b-navbar-toggle target="sidebar-1" id="navbar_toggle" />
      <b-collapse id="nav-collapse" is-nav>
        <!-- 記得同步更新手機版 -->
        <b-navbar-nav id="nav_partner">
          <b-nav-item to='/partner/guide'>合作夥伴</b-nav-item>
          <b-nav-item to='/product'>商品</b-nav-item>
          <b-nav-item to='/travel'>旅遊行程</b-nav-item>
          <b-nav-item to='/news'>最新消息</b-nav-item>
          <b-nav-item >綠色廠商</b-nav-item>
          <b-nav-item to='/event'>活動與課程</b-nav-item>
          <!-- <b-nav-item to='/certificate'>證書</b-nav-item> -->
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
        <div class="py-1 px-3" @mouseover="onOver()" @mouseleave="onLeave()">
          <b-nav-item-dropdown
            text="" ref="dropdown" no-caret>
            <template #button-content>
              <font-awesome-icon icon="fa-solid fa-circle-user"/>
              <span v-if="user.isLogin" id="nav_member"> 會員</span>
              <span v-if="!user.isLogin" id="nav_login"> 登入/註冊</span>
            </template>
            <b-dropdown-item v-if="user.group_leader.length>0 || user.group_member.length>0" to='/guidegroup'>小組專區</b-dropdown-item>
            <b-dropdown-item v-if="user.isLogin&&user.isPersonUser" to='/member'>會員中心</b-dropdown-item>
            <b-dropdown-item v-if="user.isLogin&&!user.isPersonUser" to='/business-member'>企業會員中心</b-dropdown-item>
            <b-dropdown-item v-if="user.isLogin&&!user.isPersonUser" to='/business-admin'>企業管理後台</b-dropdown-item>
            <b-dropdown-item v-if="!user.isLogin" @click="loginBtn()">登入</b-dropdown-item>
            <b-dropdown-item v-if="!user.isLogin" @click="registerBtn()">註冊</b-dropdown-item>
            <b-dropdown-item v-if="user.showBackstage" to='/admin' @click="showNavName('管理')">管理後台</b-dropdown-item>
            <b-dropdown-item v-if="user.isLogin" v-b-modal.person_logout @click="logout">
              登出 <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
            </b-dropdown-item>
          </b-nav-item-dropdown></div>
        </b-navbar-nav>
      </b-collapse>
    </div>

    <!-- 手機版導覽列sidebar -->
    <b-sidebar
      :visible="sidebarVisible"
      id="sidebar-1"
      shadow
      class="d-md-none"
      backdrop
      title="台灣ESG旅遊推廣協會"
      >
      <div>
        <b-navbar-nav>
          <b-nav-item to='/partner/guide' id="sidebar_partner">合作夥伴</b-nav-item>
          <b-nav-item to='/travel' id="sidebar_travel">旅遊行程</b-nav-item>
          <b-nav-item to='/product'>商品</b-nav-item>
          <b-nav-item to='/news'>最新消息</b-nav-item>
          <b-nav-item >綠色廠商</b-nav-item>
          <b-nav-item >活動與課程</b-nav-item>
          <!-- <b-nav-item to='/certificate'>證書</b-nav-item> -->
          <b-nav-item to='/about'>關於我們</b-nav-item>
          <b-nav-item to='/links'>友善連結</b-nav-item>
          <b-dropdown-divider v-if="user.isLogin"></b-dropdown-divider>
          <b-nav-item v-if="user.group_leader.length>0 || user.group_member.length>0" to='/guidegroup'>小組專區</b-nav-item>
          <b-nav-item v-if="user.isLogin&&user.isPersonUser" to='/member'>會員中心</b-nav-item>
          <b-nav-item v-if="user.isLogin&&!user.isPersonUser" to='/business-member'>企業會員中心</b-nav-item>
          <b-nav-item v-if="user.isLogin&&!user.isPersonUser" to='/business-admin'>企業管理後台</b-nav-item>
          <b-nav-item v-if="!user.isLogin" @click="loginBtn()">登入</b-nav-item>
          <b-nav-item v-if="!user.isLogin" @click="registerBtn()">註冊</b-nav-item>
          <b-nav-item v-if="user.showBackstage" to='/admin' @click="showNavName('管理')">管理後台</b-nav-item>
          <b-nav-item v-if="user.isLogin" v-b-modal.person_logout @click="logout">
            登出 <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket" />
          </b-nav-item>
        </b-navbar-nav>
      </div>
    </b-sidebar>
    <!-- 登入 -->
    <b-modal
      id="login_modal"
      scrollable
      :title="login_title + '登入'"
      ok-only
      no-stacking
      ok-title="登入"
      centered
      @hidden="resetForm"
      @ok='login'
      :ok-disabled="loginCheck">
      <b-form class="text-center">
        <div class="">請選擇您是個人會員或企業會員</div>
        <div class="my-1">
          <label class="subtxt mb-3" @click="switchLogin('user')" :class="person">我是個人會員</label>
          <label class="subtxt mb-3" @click="switchLogin()" :class="business">我是企業會員</label>
        </div>
        <div class="text-left">
        <b-form-group
          label-size="sm"
          class="mb-2"
          label='電子信箱'
          label-for='input_email'
          label-cols-sm="2"
          content-cols-sm
          invalid-feedback="請輸入正確格式的電子信箱">
          <b-form-input
            id="input_email"
            required
            type='text'
            placeholder="請輸入電子信箱"
            v-model="loginForm.email"
            :state="state.loginEmailCheck"
            @blur="blur_loginEmail=true"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-size="sm"
          class="mb-2"
          label='密碼'
          label-for='input_psw'
          label-cols-sm="2"
          content-cols-sm
          invalid-feedback="密碼至少為6碼">
          <b-form-input
            id="input_psw"
            required
            type='password'
            placeholder="請輸入密碼"
            v-model="loginForm.psw"
            :state="state.loginPswCheck"
            @blur="blur_loginPsw=true"
            @keydown="checkCapslock"
        ></b-form-input>
        <div v-if="capsLockOn" class="text-danger smtxt">大寫鎖定鍵為開啟狀態</div>
        </b-form-group>

        <b-form-group
          label-size="sm"
          class="mb-2"
          label='驗證碼'
          label-for='vali_code'
          label-cols-sm="2"
          content-cols-sm
          :state="state.checkVCode"
          invalid-feedback="請輸入正確驗證碼">
          <b-row>
            <b-col cols="12" class="mb-1">
              <b-form-input
                autocomplete="off"
                id="vali_code"
                required
                type='number'
                placeholder="請輸入下圖的4位數驗證碼"
                v-model="vali_code"
                :state="state.checkVCode"
                @blur="blur_valiCode=true"
              ></b-form-input>
            </b-col>
            <b-col cols="2">
              <div @click="refreshCode" style="cursor: pointer;">
                <Identify :identifyCode="identifyCode"></Identify>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
      </div>
      </b-form>
      <div class="d-flex ">
        <!-- 隱藏企業註冊 -->
        <!-- <div class="mx-auto light_txt mt-2" v-b-modal.register_modal>還不是會員? 點我註冊</div>person_register -->
        <div class="mx-auto" @click="$bvModal.hide('login_modal')">
          <router-link to='/forgot' class="subtxt">忘記密碼</router-link>
        </div>
        <!-- <div class="mx-auto subtxt">
          <div v-b-modal.person_register @click="refreshCode">還不是會員? 點我註冊</div>
        </div> -->
      </div>
    </b-modal>
    <!-- 登入END -->

<!-- 註冊:選擇個人或企業 -->
    <b-modal
      id="register_modal"
      scrollable
      title="註冊"
      hide-footer
      no-stacking
      centered>
      <div class="text-center d-flex flex-column px-sm-5">
        <!-- <b-btn v-b-modal.person_register class="btn1 m-2">個人註冊</b-btn> -->
        <b-btn v-b-modal.email_verify @click="checkEmailVerify()" class="btn1 m-2">個人註冊</b-btn>
        <b-btn @click="registerGuide()" class="btn1 m-2" v-if="$router.history.current.path !== '/register-guide'">導遊、領隊註冊</b-btn>
        <b-btn v-b-modal.business_register class="btn1 m-2">企業註冊</b-btn>
      </div>
      <div class="d-flex">
        <div class="mx-auto light_txt mt-4" v-b-modal.login_modal @click="refreshCode">已經是會員? 點我登入</div>
      </div>
    </b-modal>
    <!-- 註冊:選擇個人或企業END -->

    <!-- 新版註冊 先發信至電子信箱 -->
    <b-modal
      id="email_verify"
      scrollable
      :title="'註冊' + register_title"
      hide-footer
      no-stacking
      @hidden="resetForm"
      centered>
      <b-form-group
        class="mb-2"
        label='電子信箱'
        label-for='input_email'
        label-cols-sm="3"
        invalid-feedback="請輸入正確格式的電子信箱"
        content-label-sm>
        <!-- invalid-feedback="電子信箱格式不正確"
        :state="state.emailCheck" -->
        <b-form-input
          id="input_email"
          required
          type='text'
          v-model="verifyForm.email"
          placeholder="請輸入電子信箱"
          :state="state.emailCheck"
          @blur="blur_email = true"
        ></b-form-input>
        <!-- :state="state.emailCheck" -->
      </b-form-group>
      <b-form-group
        label='驗證碼'
        label-for='vali_code'
        label-cols-sm="3"
        :state="state.checkVCode"
        invalid-feedback="請輸入正確驗證碼"
        content-label-sm>
        <b-row>
          <b-col cols="12" class="mb-1">
            <b-form-input
              autocomplete="off"
              id="vali_code"
              required
              type='text'
              placeholder="請輸入下圖的4位數驗證碼"
              v-model="vali_code"
              @blur="blur_valiCode = true"
              :state="state.checkVCode"
            ></b-form-input>
          </b-col>
          <b-col cols="2">
            <div @click="refreshCode" style="cursor: pointer;">
              <Identify :identifyCode="identifyCode"></Identify>
            </div>
          </b-col>
        </b-row>
      </b-form-group>
      <div class="text-center mt-4">
        <div class="">請選擇要註冊個人會員或企業會員</div>
        <div class="smtxt">導遊、領隊請選個人會員</div>
        <div class="my-1">
          <label class="subtxt mb-3" @click="switchUorB('user')" :class="personR">我是個人會員</label>
          <label class="subtxt mb-3" @click="switchUorB()" :class="business">我是企業會員</label>
        </div>
      </div>
      <div class="text-center mt-3 modal-footer">
        <b-btn class="btn-primary" @click="sendVerify"
        :disabled="verifyForm.isPersonRegister===null || verifyForm.email==='' ||state.emailCheck=== false || !state.checkVCode2">
          送出註冊信
        </b-btn>
      </div>
    </b-modal>

<!-- 舊個人註冊 -->
    <!-- <b-modal
      id="person_register"
      scrollable
      title="個人註冊"
      ok-only
      no-stacking
      ok-title="註冊"
      no-close-on-backdrop
      centered
      @hidden="resetForm"
      @ok='person_register'
      :ok-disabled="this.SignUpB.vali_code !== this.identifyCode">
      <b-form class="text-center">
        <b-form-group
          v-if="user.remember_referrer_name.length>0"
          label='推薦人帳號'
          label-for='remember_referrer_name'
          label-cols="4">
          <b-form-input
          id="remember_referrer_name"
          disabled
          v-model="user.remember_referrer_name"
        ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="user.guideGroupId!==''"
          label='小組組長'
          label-for='joinGuideGroup'
          label-cols="4">
          <b-form-input
          id="joinGuideGroup"
          disabled
          v-model="user.remember_referrer_name"
        ></b-form-input>
        </b-form-group>
        <b-alert show variant="primary" class="p-3 subtxt text-left"
          v-if="user.guideGroupId!==''">
          加入小組後，組長可得知您的帳號、姓名、出生年次及性別，並可寄email給您，加入小組即代表您同意此規範。
        </b-alert>
        <b-form-group
          class="required"
          label='姓名'
          label-for='input_name'
          label-cols="4"
          invalid-feedback="請輸入姓名"
          :state="state.name">
          <b-form-input
          id="input_name"
          required
          type='text'
          v-model="SignUpP.name"
          placeholder="請輸入姓名"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='帳號'
          label-for='input_username'
          label-cols="4"
          invalid-feedback="帳號格式不正確"
          :state="state.usernameCheck6">
          <b-form-input
          id="input_username"
          required
          type='text'
          v-model="SignUpP.username"
          placeholder="請設定至少六碼的帳號"
          :state="state.usernameCheck6"
          @blur="checkUsername"
          onkeyup="value=value.replace(/[^\a-\z\A-\Z0-9]/g,'')"
          onpaste="value=value.replace(/[^\a-\z\A-\Z0-9]/g,'')"
          oncontextmenu="value=value.replace(/[^\a-\z\A-\Z0-9]/g,'')"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='電子信箱'
          label-for='input_email'
          label-cols="4"
          invalid-feedback="電子信箱格式不正確"
          :state="state.emailCheck">
          <b-form-input
          id="input_email"
          required
          type='email'
          v-model="SignUpP.email"
          placeholder="請輸入電子信箱"
          @blur="checkEmail"
          :state="state.emailCheck"
        ></b-form-input>
        </b-form-group>
        <b-form-group
          class="required"
          label='出生日期'
          label-for='input_birthY'
          label-cols="4">
          <b-row>
            <b-col cols="12" sm="4" class="pr-sm-0">
              <b-form-input
                type="number"
                id="input_birthY"
                v-model="SignUpP.birthY"
                placeholder="西元年">
              </b-form-input>
            </b-col>
            <b-col cols="12" sm="4" class="px-sm-1">
              <b-form-input
                type="number"
                id="input_birthM"
                v-model="SignUpP.birthM"
                placeholder="月">
              </b-form-input>
            </b-col>
            <b-col cols="12" sm="4" class="pl-sm-0">
              <b-form-input
                type="number"
                id="input_birthD"
                v-model="SignUpP.birthD"
                placeholder="日">
              </b-form-input>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group
          class="required"
          label='性別'
          label-for='input_gender'
          label-cols="4">
          <b-form-select
            type="number"
            id="input_gender"
            :options='genders'
            v-model="SignUpP.gender">
          </b-form-select>
        </b-form-group>

        <b-form-group
          class="required"
          label='密碼'
          label-for='input_psw'
          label-cols="4"
          :state="state.pswCheck6">
          <b-form-input
            id="input_psw"
            required
            type='password'
            placeholder="請設定至少六碼的密碼"
            v-model="SignUpP.psw"
            :state="state.pswCheck6"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='密碼確認'
          label-for='password'
          label-cols="4"
          :state="state.pswDBCheck">
          <b-form-input
            id="password"
            required
            type='password'
            placeholder="請再次輸入密碼"
            v-model="pswCheck"
            :state="state.pswDBCheck"
        ></b-form-input>
        </b-form-group>

        <b-form-group
          class="required"
          label='驗證碼'
          label-for='vali_code'
          label-cols="4"
          :state="state.checkVCode"
          invalid-feedback="請輸入正確驗證碼">
          <b-row>
            <b-col cols="12" class="mb-1">
              <b-form-input
                id="vali_code"
                required
                type='text'
                placeholder="請輸入下圖的4位數驗證碼"
                v-model="SignUpB.vali_code"
                :state="state.checkVCode"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <div @click="refreshCode">
                <Identify :identifyCode="identifyCode"></Identify>
              </div>
            </b-col>
          </b-row>
        </b-form-group>

        <div class="d-flex">
          <div v-b-modal.register_modal class="mx-auto my-2 light_txt">上一步</div>
        </div>
      </b-form>
    </b-modal> -->
<!-- 個人註冊END -->

<!-- 舊企業註冊 -->
    <!-- <b-modal
      id="business_register"
      scrollable
      title="企業註冊"
      size="xl"
      ok-only
      no-stacking
      ok-title="註冊"
      no-close-on-backdrop
      centered
      @hidden="resetForm"
      @ok='businessRegister'
      :ok-disabled="this.SignUpB.vali_code !== this.identifyCode">
      <b-form class="px-1 px-lg-3">
        <b-row>
          <b-col lg="6">
            <div class="mb-3 font-weight-bold text-center">聯絡人資料</div>
            <b-form-group
              label='姓名'
              label-for='input_name'
              label-cols="4"
              class="required">
              <b-form-input
              id="input_name"
              required
              type='text'
              v-model="SignUpB.name"
              placeholder="請輸入姓名"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              class="required"
              label='帳號'
              label-for='input_username'
              label-cols="4"
              invalid-feedback="帳號格式不正確"
              :state="state.usernameCheck6">
              <b-form-input
              id="input_username"
              required
              type='text'
              v-model="SignUpB.username"
              placeholder="請設定至少六碼的帳號"
              :state="state.usernameCheck6"
              @blur="checkUsername"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label='電子信箱'
              label-for='input_email'
              label-cols="4"
              class="required">
              <b-form-input
                id="input_email"
                required
                type='email'
                v-model="SignUpB.email"
                placeholder="請輸入電子信箱"
                @blur="checkEmail('business')"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label='密碼'
              label-for='input_psw'
              label-cols="4"
              class="required">
              <b-form-input
                id="input_psw"
                required
                type='password'
                placeholder="請設定至少六碼的密碼"
                v-model="SignUpB.psw"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='密碼確認'
              label-for='password'
              label-cols="4"
              class="required">
              <b-form-input
                id="password"
                required
                type='password'
                placeholder="請再次輸入密碼"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label='行動電話'
              label-for='input_cellphone'
              label-cols="4">
              <b-form-input
                id="input_cellphone"
                type='text'
                v-model="SignUpB.cellphone"
                placeholder="請輸入行動電話"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label='聯絡傳真'
              label-for='input_fax'
              label-cols="4">
              <b-form-input
                id="input_fax"
                type='text'
                v-model="SignUpB.fax"
                placeholder="請輸入聯絡傳真"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label='LINE ID'
              label-for='lineid'
              label-cols="4">
              <b-form-input
                id="lineid"
                type='text'
                v-model="SignUpB.lineId"
                placeholder="請輸入LINE ID"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <div class="mb-3 font-weight-bold mt-4 mt-lg-0 text-center">申請公司資料</div>
            <b-form-group
              label-cols="6"
              label-for='industry'
              class="required text-left"
              label="是否為旅遊相關產業">
              <b-form-radio
                v-model="isTravelIndustry"
                :value= true
                class="custom-control-inline"
              >是
              </b-form-radio>
              <b-form-radio
                v-model="isTravelIndustry"
                :value= false
                class="custom-control-inline"
              >否
              </b-form-radio>
            </b-form-group>
            <b-form-group
              v-if="isTravelIndustry"
              label-cols="4"
              label-for='industry'
              class="required"
              label="旅遊相關產業別">
              <b-form-select
                id="industry"
                v-model="SignUpB.industry" :options="travelIndustryOptions">
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="isTravelIndustry===false"
              label-cols="4"
              label-for='industry'
              class="required"
              label="企業行業別">
              <b-form-select
                id="industry"
                v-model="SignUpB.industry" :options="industryOptions">
              </b-form-select>
            </b-form-group>
            <b-form-group
              label='名片'
              label-for='upload'
              label-cols="4"
              class="required">
              <div class="text-left">
                <img class="imgPreview" :src="imgPreview" v-if="imgPreview"/>
                <input id="uploadlicense" class="d-none" accept="image/*" type='file' @change="docChange"/>
                <label class="btn1" for="uploadlicense">選擇檔案</label>
              </div>
            </b-form-group>
            <b-form-group
              v-if="SignUpB.industry === 'ti3'"
              label='觀光局註冊編號'
              label-for='tourismNumber'
              label-cols="4"
              class="required">
              <b-form-input
                id="tourismNumber"
                required
                type='text'
                placeholder="請輸入觀光局註冊編號"
                v-model="SignUpB.tourismNumber"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="SignUpB.industry === 'ti3'"
              label='品保編號'
              label-for='qaNumber'
              label-cols="4"
              class="required">
              <b-form-input
                id="qaNumber"
                required
                type='text'
                placeholder="請輸入品保編號"
                v-model="SignUpB.qaNumber"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='統一編號'
              label-for='taxIdNumber'
              label-cols="4"
              class="required">
              <b-form-input
                id="taxIdNumber"
                required
                type='text'
                placeholder="請輸入統一編號"
                v-model="SignUpB.taxIdNumber"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='公司名稱'
              label-for='unit_name'
              label-cols="4"
              class="required">
              <b-form-input
                id="unit_name"
                required
                type='text'
                placeholder="請輸入公司名稱"
                v-model="SignUpB.unit_name"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='負責人'
              label-for='manager'
              label-cols="4"
              class="required">
              <b-form-input
                id="manager"
                required
                type='text'
                placeholder="請輸入負責人"
                v-model="SignUpB.manager"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label='聯絡電話'
              label-for='unit_tel'
              label-cols="4"
              class="required">
              <b-form-input
                id="unit_tel"
                required
                type='text'
                placeholder="請輸入聯絡電話"
                v-model="SignUpB.unit_tel"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label='登記地址'
              label-for='unit_add'
              label-cols="4"
              class="required">
              <b-form-input
                id="unit_add"
                required
                type='text'
                placeholder="請輸入登記地址"
                v-model="SignUpB.unit_add"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label='公司簡介'
              label-for='intro'
              label-cols="4">
              <b-form-input
                id="intro"
                type='text'
                placeholder="請輸入公司簡介"
                v-model="SignUpB.unitIntro"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='公司主要經營旅遊區域 / 主題'
              label-for='travelClass'
              label-cols="4">
              <b-form-input
                id="travelClass"
                type='text'
                placeholder="請輸入公司主要經營旅遊區域 / 主題"
                v-model="SignUpB.unitTravelClass"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='公司網址'
              label-for='url'
              label-cols="4">
              <b-form-input
                id="url"
                type='text'
                placeholder="請輸入公司網址"
                v-model="SignUpB.unitUrl"
            ></b-form-input>
            </b-form-group>
            <b-form-group
              label='驗證碼'
              label-for='vali_code'
              label-cols="4"
              class="required">
              <b-row>
                <b-col cols="12" class="mb-1">
                  <b-form-input
                    id="vali_code"
                    required
                    type='text'
                    placeholder="請輸入下圖的4位數驗證碼"
                    v-model="SignUpB.vali_code"
                  ></b-form-input>
                </b-col>
                <b-col cols="2">
                  <div @click="refreshCode" style="cursor: pointer;">
                    <Identify :identifyCode="identifyCode"></Identify>
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div class="d-flex">
        <div v-b-modal.register_modal class="mx-auto my-2 light_txt">上一步</div>
      </div>
    </b-modal> -->

<!-- 舊個人註冊成功 -->
    <b-modal
      id="registerSuccess"
      scrollable
      title="註冊成功"
      no-stacking
      hide-footer
      centered>
      <div class="py-3 text-center">
        <h1><font-awesome-icon class="my-3 check_green" icon="fa-solid fa-circle-check"></font-awesome-icon></h1>
        <div class="py-2">請至信箱 <strong>{{email}}</strong> 收信並完成認證</div>
        <!-- <div class="py-1">沒收到信件？<router-link to='/send-verify'>點我重新寄送認證信</router-link></div> -->
        <div class="py-2"><strong>完成認證後，如您為導遊、領隊、嚮導、售導，請登入後至會員中心填寫資料</strong></div>
      </div>
    </b-modal>

  </b-navbar>
</template>

<script>
// 驗證碼
import Identify from '@/components/Identify.vue'
import validator from 'validator'
export default {
  components: {
    Identify
  },
  data () {
    return {
      capsLockOn: false,
      sidebarVisible: false,
      myCallbacks: {
        onStart: this.onStartCallback,
        onNextStep: this.NextStepCallback
      },
      // 網頁版導覽步驟
      tourStepWeb: [
        // 0
        {
          params: { highlight: true, enableScrolling: false },
          target: '#nav_partner',
          content: '導覽列可查看各頁面。'
          // 「合作夥伴」有導遊、領隊及各企業資訊。<br>
        },
        // 1
        {
          params: { highlight: true, enableScrolling: false },
          // 已登入-會員中心 未登入-登入註冊
          target: this.$store.getters['user/user'].isLogin ? '#nav_member' : '#nav_member',
          content: this.$store.getters['user/user'].isLogin ? '會員中心' : '登入'
        }
      ],
      // 手機版導覽步驟
      tourStepMobile: [
        // 0
        // {
        //   params: { highlight: false, enableScrolling: false },
        //   // sidebar_partner
        //   target: window.screen.width < 576 ? '#sidebar_partner' : '#nav_partner',
        //   content: '「合作夥伴」有導遊、領隊及各企業資訊'
        // },

        // 導覽列有「合作夥伴」、「商品」、「旅遊行程」、「最新消息」、「活動與課程」、「證書」
        {
          params: { highlight: true, enableScrolling: false },
          target: '#navbar_toggle',
          content: '按此可打開導覽列。'
        },
        {
          params: { highlight: true, enableScrolling: true },
          target: '#sidebar_partner',
          content: '按此可打開導覽列。'
        }
      ],
      blur_loginPsw: false,
      blur_loginEmail: false,
      blur_valiCode: false,
      blur_email: false,
      vali_code: '',
      verifyForm: {
        remember: {
          guideGroupId: '',
          remember_referrer_id: '',
          remember_referrer_name: '',
          remember_group_name: ''
        },
        email: '',
        isPersonRegister: null
      },
      login_title: '',
      register_title: '',
      personR: 'btn_unactive',
      business: 'btn_unactive',
      person: 'btn2',
      return: false,
      imgPreview: null,
      doc: '',
      isTravelIndustry: null,
      modalSubmitting: false,
      travelIndustryOptions: [
        { text: '旅宿業者', value: 'ti1' },
        { text: '交通業者', value: 'ti2' },
        { text: '旅行社', value: 'ti3' },
        { text: '餐飲業 ', value: 'ti4' },
        { text: '景點', value: 'ti5' },
        { text: '購物 ', value: 'ti6' }
      ],
      industryOptions: [
        { text: '農、林、漁、牧業', value: 'a' },
        { text: '礦業及土石採取業', value: 'b' },
        { text: '製造業', value: 'c' },
        { text: '電力及燃氣供應業', value: 'd' },
        { text: '用水供應及污染整治業 ', value: 'e' },
        { text: '營建工程業 ', value: 'f' },
        { text: '批發及零售業', value: 'g' },
        { text: '運輸及倉儲業', value: 'h' },
        { text: '住宿及餐飲業', value: 'i' },
        { text: '出版影音及資通訊業', value: 'j' },
        { text: '金融及保險業', value: 'k' },
        { text: '不動產業', value: 'l' },
        { text: '專業、科學及技術服務業', value: 'm' },
        { text: '支援服務業', value: 'n' },
        { text: '公共行政及國防；強制性社會安全', value: 'o' },
        { text: '教育業', value: 'p' },
        { text: '醫療保健及社會工作服務業', value: 'q' },
        { text: '藝術、娛樂及休閒服務業 ', value: 'r' },
        { text: '其他服務業', value: 's' }
      ],
      // 驗證碼
      identifyCode: '',
      // 驗證碼規則
      identifyCodes: '1234567890',
      SignUpB: {
        name: '',
        username: '',
        email: '',
        psw: '',
        pswCheck: '',
        vali_code: '',
        tel: '',
        add: '',
        cellphone: '',
        fax: '',
        lineId: '',
        // travelIndustry: '',
        industry: '',
        unitUrl: '',
        tourismNumber: '',
        qaNumber: '',
        unitTravelClass: '',
        unitIntro: '',
        taxIdNumber: '',
        unit_name: '',
        manager: '',
        unit_tel: '',
        unit_add: ''
      },
      loginForm: {
        isPersonUser: true,
        email: '',
        psw: ''
      },
      SignUpP: {
        isPersonRegister: null,
        lineId: '',
        guideGroupId: '',
        referrer_id: '0',
        vali_code: '',
        name: '',
        username: '',
        email: '',
        birthY: '',
        birthM: '',
        birthD: '',
        gender: 'null',
        psw: ''
      },
      emailCanUse: false,
      pswCheck: '',
      genders: [
        { value: null, text: '請選擇', disabled: true },
        { value: '男', text: '男' },
        { value: '女', text: '女' },
        { value: '不公開', text: '不公開' }
      ],
      email: ''
    }
  },
  computed: {
    loginCheck () {
      // console.log(this.state.checkVCode)
      if (this.vali_code === this.identifyCode &&
      validator.isEmail(this.loginForm.email) &&
      this.loginForm.psw.length >= 6) {
        return false
      } else return true
    },
    state () {
      return {
        usernameCheck6: !this.SignUpP.username ? null : this.SignUpP.username.length >= 6,
        checkVCode: !this.blur_valiCode ? null : this.vali_code === this.identifyCode ? null : false,
        checkVCode2: this.vali_code === '' ? null : this.vali_code === this.identifyCode,
        pswDBCheck: this.pswCheck === '' ? null : this.SignUpP.psw === this.pswCheck,
        pswCheck6: !this.SignUpP.psw ? null : this.SignUpP.psw.length >= 6,
        emailCheck: !this.blur_email ? null : validator.isEmail(this.verifyForm.email) ? null : false,
        loginEmailCheck: !this.blur_loginEmail ? null : validator.isEmail(this.loginForm.email) ? null : false,
        loginPswCheck: !this.blur_loginPsw ? null : this.loginForm.psw.length >= 6 ? null : false
      }
    }
  },
  methods: {
    // 大寫鎖定警告
    checkCapslock (e) {
      if (e.getModifierState('CapsLock')) {
        this.capsLockOn = true
      } else this.capsLockOn = false
    },
    onStartCallback () {
      // this.sidebarVisible = true
    },
    NextStepCallback (current) {
      if (current === 0 && window.screen.width < 576) {
        this.sidebarVisible = true
        window.scrollTo(0, 20) // sidebar
      }
    },
    switchUorB (x) {
      if (x === 'user') {
        this.verifyForm.isPersonRegister = true
        this.business = 'btn_unactive'
        this.personR = 'btn2'
        this.register_title = '個人會員'
      } else {
        this.verifyForm.isPersonRegister = false
        this.personR = 'btn_unactive'
        this.business = 'btn2'
        this.register_title = '企業會員'
      }
    },
    switchLogin (x) {
      if (x === 'user') {
        this.loginForm.isPersonUser = true
        this.business = 'btn_unactive'
        this.person = 'btn2'
        this.login_title = '個人會員'
      } else {
        this.loginForm.isPersonUser = false
        this.person = 'btn_unactive'
        this.business = 'btn2'
        this.login_title = '企業會員'
      }
    },
    async checkEmailVerify () {
      try {
        // 註冊請求
        const { data } = await this.api.post('php_modules/auth-file/email-verify2.php?type=r', this.SignUpP)
        console.log(data)
        // if (data.success) {
        // this.$store.commit('user/checkEmail', data.jwt)
        //   this.$swal({
        //     type: 'success',
        //     text: '已送出驗證信'
        //   })
        // } else alert(data.message)
        // this.$router.go(0)
        // this.$router.push('/')
      } catch (error) {
        console.log(error)
      }
      this.$bvModal.show('email_verify')
    },
    async sendVerify () {
      const user = this.$store.getters['user/user']
      // console.log(this.$store.getters['user/user'])
      if (this.verifyForm.email === '') {
        alert('請填寫email')
        return
      }
      this.verifyForm.remember.guideGroupId = user.guideGroupId
      this.verifyForm.remember.remember_referrer_id = user.remember_referrer_id
      this.verifyForm.remember.remember_referrer_name = user.remember_referrer_name
      this.verifyForm.remember.remember_group_name = user.remember_group_name
      console.log(this.verifyForm)
      this.email = this.verifyForm.email
      this.$bvModal.show('waitModal')
      try {
        // 發驗證信
        // if (this.verifyForm.isPersonRegister === true)
        const { data } = await this.api.post(
          'php_modules/auth-file/email-verify2.php?type=c', this.verifyForm)
        console.log(data)
        if (data.success) {
          this.$bvModal.hide('waitModal')
          this.$swal({
            // title: '已送出驗證信' + this.email,
            type: 'success',
            text: '已送出驗證信，請至 ' + this.email + ' 收信，並點擊連結完成註冊。若沒收到信件，請檢查垃圾信件。'
          })
          this.$bvModal.hide('email_verify')
          this.email = ''
        } else {
          alert(data.message)
          this.$bvModal.hide('waitModal')
        }
        // this.$router.go(0)
        // this.$router.push('/')
      } catch (error) {
        console.log(error)
      }
    },
    docChange (event) {
      const [file] = event.target.files
      if (file) {
        this.imgPreview = URL.createObjectURL(file)
        this.doc = file
      }
    },
    registerGuide () {
      this.$bvModal.hide('register_modal')
      this.$router.push('/register-guide')
    },
    onOver () {
      this.$refs.dropdown.visible = true
    },
    onLeave () {
      this.$refs.dropdown.visible = false
    },
    loginBtn () {
      this.refreshCode()
      // console.log(this.identifyCode)
      this.$bvModal.show('login_modal')
    },
    registerBtn () {
      this.refreshCode()
      this.$bvModal.show('email_verify')
    },
    // 登入 呼叫store/user/actions的login()，傳this.loginForm
    login () {
      this.$store.dispatch('user/login', this.loginForm)
    },
    // 登出
    logout () {
      this.$store.commit('user/logout')
      // 如果當前頁面不在首頁才跳至首頁
      // if (this.$router.history.current.path !== '/') {
      //   this.$router.push('/')
      // }
    },

    // 舊個人註冊
    // async person_register (event) {
    //   this.return = false
    //   // 擋預設
    //   event.preventDefault()
    //   if (this.return) return
    //   // 檢查生日日期是否存在
    //   const birthCheck = this.SignUpP.birthY + '/' + this.SignUpP.birthM.padStart(2, '0') + '/' + this.SignUpP.birthD.padStart(2, '0')
    //   // console.log(validator.isAfter(birthCheck, [new Date()]))
    //   if (validator.isDate(birthCheck) !== true || this.SignUpP.birthY < 1930) {
    //     alert('請輸入正確的生日')
    //     return
    //   }
    //   // 檢查是否完成必填、帳密>6字
    //   if (this.SignUpP.name.length < 2 || !this.SignUpP.email || !this.SignUpP.birthY || !this.SignUpP.birthM ||
    //   !this.SignUpP.birthD || this.SignUpP.gender === 'null' || !this.SignUpP.psw || this.SignUpP.username.length < 6 ||
    //   this.SignUpP.psw.length < 6 || this.emailCanUse === false) {
    //     alert('請填寫必填欄位且帳號密碼須至少六碼')
    //     return
    //   }
    //   if (this.SignUpP.psw !== this.pswCheck) {
    //     alert('密碼確認請輸入一致的密碼')
    //     return
    //   }
    //   this.email = this.SignUpP.email
    //   await this.$swal({
    //     title: '請確認email是否正確： ' + this.email,
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#dc3545',
    //     confirmButtonText: '確定',
    //     cancelButtonText: '回去修改'
    //   }).then((result) => {
    //     if (result.isConfirmed === false) {
    //       this.return = true
    //     }
    //   })
    //   if (this.return) return
    //   // 代入推薦人ID
    //   if (this.$store.state.user.remember_referrer_id !== '') {
    //     this.SignUpP.referrer_id = this.$store.state.user.remember_referrer_id
    //   }
    //   if (this.$store.state.user.guideGroupId !== '') {
    //     this.SignUpP.guideGroupId = this.$store.state.user.guideGroupId
    //   }
    //   console.log(this.SignUpP.referrer_id)
    //   try {
    //     // 註冊請求
    //     const { data } = await this.api.post('php_modules/auth-file/user.php?type=c', this.SignUpP)
    //     console.log(data)
    //     if (data.success === true) {
    //       // 註冊成功後清除介紹人
    //       this.$store.commit('user/clearReferrer')
    //       this.$store.commit('user/clearGuideGroup')
    //       try {
    //         // 註冊成功後寄認證信
    //         const { data } = await this.api.get(
    //           'php_modules/auth-file/email-verify.php?type=c&email=' + this.SignUpP.email)
    //         console.log(data)
    //         if (data.success === true) {
    //           this.$bvModal.show('registerSuccess')
    //           // 如果當前是在推薦人頁面，註冊完回首頁
    //           const currentPage = this.$route.name
    //           if (currentPage === 'ReferrerPage') this.$router.push('/')
    //         } else {
    //           alert(data.message)
    //         }
    //       } catch (error) {
    //         console.log(error)
    //       }
    //     } else alert(data.message)
    //     // 隱藏modal
    //     this.$bvModal.hide('person_register')
    //     // this.$router.go(0)
    //     // this.$router.push('/')
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    // 舊企業註冊
    // businessRegister (event) {
    //   event.preventDefault()
    //   if ((this.isTravelIndustry) && (this.SignUpB.industry.length <= 0)) {
    //     alert('請選擇旅遊產業別')
    //     console.log(this.isTravelIndustry)
    //     return
    //   }
    //   console.log(this.SignUpB)
    // },
    resetForm (event) {
      if (this.modalSubmitting) {
        event.preventDefault()
        return
      }
      this.blur_loginPsw = false
      this.blur_loginEmail = false
      this.blur_valiCode = false
      this.blur_email = false
      this.refreshCode()
      this.vali_code = ''
      this.verifyForm = {
        remember: {
          guideGroupId: '',
          remember_referrer_id: '',
          remember_referrer_name: ''
        },
        email: '',
        isPersonRegister: null
      }
      this.personR = 'btn_unactive'
      this.business = 'btn_unactive'
      this.person = 'btn2'
      this.login_title = '個人會員'
      this.register_title = ''
      this.loginForm = {
        isPersonUser: true,
        email: '',
        psw: ''
      }
      this.SignUpB = {
        name: '',
        username: '',
        email: '',
        psw: '',
        pswCheck: '',
        vali_code: '',
        tel: '',
        add: '',
        cellphone: '',
        fax: '',
        industry: '',
        taxIdNumber: '',
        unit_name: '',
        manager: '',
        unit_tel: '',
        unit_add: ''
      }
      this.SignUpP = {
        isPersonRegister: null,
        lineId: '',
        guideGroupId: '',
        referrer_id: '0',
        vali_code: '',
        name: '',
        username: '',
        email: '',
        birthY: '',
        birthM: '',
        birthD: '',
        gender: 'null',
        psw: ''
      }
      this.pswCheck = ''
      this.emailCanUse = false
    },
    // 檢查帳號是否重複
    async checkUsername () {
      if (!this.SignUpP.username) {
        return
      }
      try {
        const { data } = await this.api.post('php_modules/auth-file/check-user.php?id=username', this.SignUpP)
        // console.log(data)
        if (data.success === false) {
          alert('帳號已存在')
          this.SignUpP.username = ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 檢查Email是否重複
    async checkEmail (x) {
      if (!this.SignUpP.email) {
        return
      }
      if (x === 'business' && !this.SignUpB.email) {
        // console.log('!this.SignUpB')
        return
      }
      try {
        const { data } = await this.api.post('php_modules/auth-file/check-user.php?id=email', this.SignUpP)
        // console.log(data)
        if (data.success === false) {
          alert('電子信箱已被註冊')
          this.SignUpP.email = ''
        } else this.emailCanUse = true
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted () {
    // 如果是/page-guide，則開始導覽教學
    const startGuidedTour = this.$route.name === 'HomeGuide'
    if (startGuidedTour) {
      // this.sidebarVisible = true
      if (window.screen.width < 576) { // 行動版
        this.$tours.home_tour_mobile.start()
      } else {
        // 網頁版
        this.$tours.home_tour_web.start()
      }
    }
    // this.verifyForm.isPersonRegister = true
    // this.business = 'btn_unactive'
    // this.personR = 'btn2'
    // 進頁面生成隨機驗證碼
    this.refreshCode()
    // this.$bvModal.show('email_verify')
    // this.$bvModal.show('login_modal')
  }
}
</script>

<style lang="scss">
  .navbar-light .navbar-nav .nav-link  {
    // color: var(--mytheme2) !important;
    // font-size: 15px;
    letter-spacing: 1px;
  }
#navbar {
  letter-spacing: 1px;
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.05);
  border-bottom: 1px solid rgb(231, 231, 231);
  .navbar-toggler {
    border: none;
  }
  .nav-item {
    margin: 0 auto;
  }
  .nav_img {
    width: 60px;
  }
  @media (min-width: 768px) {
    .nav_img {
      width: 100px;
    }
    .container {
      padding: 0;
      max-width: 740px;
    }
  }
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
}
#business_register {
  img {
    width: 100%;
  }
  .col {
    display: flex;
    align-items: center;
  }
}
// modal
.modal-dialog {
  & * {
  transition: .5s;
  }
  .modal-header {
    justify-content: center;
    // border: none;
    // background-color: var(--mytheme);
    button {
      color: var(--myprimary);
    }
  }
  .modal-title {
    letter-spacing: 1px;
    font-weight: 600;
    color: var(--mytheme2);
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .modal-footer {
    border: none;
    padding: 0 0 .8rem;
    justify-content: center;
    button.btn-primary {
      background-color: var(--mytheme2);
      border: none;
      padding: .5rem 1.8rem;
      border-radius: 50px;
      &:not(.disabled):hover {
        filter: brightness(1.1);
      }
    }
    button {
      border: none;
      padding: .5rem 1.8rem;
      border-radius: 50px;
    }
  }
  button.close:not(.b-form-tag-remove) {
    position: absolute;
    right: 10px;
    color: var(--myfont);
  }

  .mybtn {
    border-radius: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
  }
  .person {
    background-color: var(--myprimary);
    color: var(--myfont);
  }
  .business {
    background-color: var(--mytheme2);
    color: #fff;
  }
  // 必填欄位*
  // .required .col-form-label:not(.norequired)::after {
  //   content: ' *';
  //   color: var(--myred);
  // }
  .required>.col-form-label::after {
    content: ' *';
    color: var(--myred);
  }

  // 出生日期input隱藏箭頭
  input#input_birthY::-webkit-outer-spin-button,
  input#input_birthY::-webkit-inner-spin-button,
  input#input_birthM::-webkit-outer-spin-button,
  input#input_birthM::-webkit-inner-spin-button,
  input#input_birthD::-webkit-outer-spin-button,
  input#input_birthD::-webkit-inner-spin-button,
  #vali_code::-webkit-outer-spin-button,
  #vali_code::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input#input_birthY[type=number],
  input#input_birthM[type=number],
  input#input_birthD[type=number],
  #vali_code {
    -moz-appearance: textfield;
  }
  .light_txt {
    color: rgb(95, 95, 95);
    &:hover {
      color: var(--mytheme2);
      // filter: brightness(1.3);
    }
  }
}
.swal2-container .swal2-html-container {
  padding-top: 1rem;
}

#register_modal___BV_modal_body_ {
  padding: 3rem 1rem;
}
// 註冊成功問是否導遊modal
#registerSuccess___BV_modal_body_ {
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    max-width: 300px;
    background-color: var(--mytheme2);
    border: none;
  }
  p {
    cursor: pointer;
  }
}
#app .navbar .dropdown-menu {
  right: -10px;
  left: auto;
}
</style>
